import { combineReducers } from 'redux';
import UserReducer from '../views/SignIn/reducers';
import { snackbarReducer } from 'react-redux-snackbar';
import AppUsersReducer from '../views/UserList/reducers';
import CustomerReducer from '../views/CustomersList/reducers';

export default combineReducers({
  UserReducer,
  AppUsersReducer,
  CustomerReducer,
  snackbar: snackbarReducer
});
