import React from 'react';
import { makeStyles } from '@material-ui/styles';
import BillsTable from './components/BillsTable';
import BillsToolbar from './components/BillsToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center'
  }
}));

const BillsList = props => {
  const classes = useStyles();
  const tableRef = React.createRef();

  const refreshTableData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  return (
    <div
      className={classes.root}
      style={{ background: props.isDashboard ? '#fff' : '' }}>
      <BillsToolbar refreshTableData={refreshTableData} />
      <div className={classes.content}>
        <BillsTable tableRef={tableRef} />
      </div>
    </div>
  );
};

export default BillsList;
