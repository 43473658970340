import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { Provider } from 'react-redux';
import store from './store';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import SessionHandler from './SessionHandler';
import { Snackbar } from 'react-redux-snackbar';
import { history } from './helpers/history';
import { setUpAxiosConfig } from './helpers/network-utils';
const API_URL = process.env.REACT_APP_API_URL;

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    setUpAxiosConfig(API_URL);
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HashRouter history={history}>
            <Snackbar />
            <SessionHandler />
          </HashRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}
