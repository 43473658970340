import { LOGIN_SUCCESS, LOGIN_STARTED, LOGIN_FAILURE, LOG_OUT } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialUserState = user ? { isLoggedIn: true, isValidatedFromState: true, user } : {};


const UserReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.data,
        isValidatedFromState: false
      }
    case LOGIN_STARTED:
      return {
        ...state,
        isLoggingIn: true
      }
    case LOGIN_FAILURE:
      return {};
    case LOG_OUT:
      return {};
    default:
      return state;
  }
};

export default UserReducer;
