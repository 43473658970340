import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import moment from 'moment';

const promiseOptions = inputValue =>
  new Promise((resolve, reject) => {
    const url = `/api/getCustomersByNameOrPhone/${inputValue}`;
    axios.get(url).then(result => {
      const options = result.data.map(option => {
        return {
          label: `${option.firstName} ${option.lastName}`,
          value: option.id
        };
      });
      resolve(options);
    });
  });

const schema = {
  customerId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  receivedAmount: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: true
  },
  receivedAt: {
    datetime: {
      dateOnly: true,
      latest: moment.utc()
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const PaymentsToolbar = props => {
  const dispatch = useDispatch();
  const { className, refreshTableData, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormState({
      isValid: false,
      touched: {},
      values: { receivedAt: new Date().toISOString().substring(0, 10) },
      errors: {}
    });
    setRecordingPayment(false);
    setOpen(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    values: { receivedAt: new Date().toISOString().substring(0, 10) },
    errors: {}
  });
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    console.log(formState);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleCustomerSelect = option => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        customerId: option ? option.value : ''
      },
      touched: {
        ...formState.touched,
        customerId: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isRecordingPayment, setRecordingPayment] = React.useState(false);

  const handleCreateBill = async () => {
    const { customerId, receivedAmount, notes, receivedAt } = formState.values;

    const formData = {
      customerId,
      receivedAmount,
      notes,
      receivedAt: new Date(receivedAt)
    };
    try {
      setRecordingPayment(true);
      const url = '/api/addPayment';
      await axios.post(url, formData);
      refreshTableData();
      dispatch(
        showSnack('RECORD_PAYMENT_SUCCESS', {
          label: 'Payment Added Successfully ..!!',
          timeout: 3000
        })
      );
      handleClose();
    } catch (e) {
      setRecordingPayment(false);
      console.log('error', e);

      dispatch(
        showSnack('RECORD_PAYMENT_ERROR', {
          label: 'Something went wrong..!',
          timeout: 3000
        })
      );
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button color="primary" onClick={handleClickOpen} variant="contained">
          Record Payment
        </Button>
      </div>
      <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} open={open}>
        <DialogTitle className={classes.modalHeader}>
          Record New Payment
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Typography>Customer</Typography>
              <Box py={1}>
                <div
                  style={{
                    border: hasError('customerId') ? '1px solid red' : ''
                  }}>
                  <AsyncSelect
                    backspaceRemovesValue
                    defaultOptions={[
                      { label: 'Type to Search', isDisabled: true, value: '-1' }
                    ]}
                    isClearable
                    loadOptions={debounce(promiseOptions, 500)}
                    name="customerId"
                    noOptionsMessage={() => <>No Customers Found</>}
                    onChange={handleCustomerSelect}
                    placeholder={<div>Type to search</div>}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('receivedAmount')}
                fullWidth
                label="Received Amount"
                margin="dense"
                name="receivedAmount"
                onChange={handleChange}
                placeholder="Received Amount"
                required
                value={formState.values.receivedAmount || ''}
                variant="standard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('receivedAt')}
                fullWidth
                label="Received Date"
                margin="dense"
                format="DD-MM-YYYY"
                name="receivedAt"
                onChange={handleChange}
                placeholder="Received Date"
                required
                defaultValue={new Date().toISOString().substring(0, 10)}
                value={formState.values.receivedAt || ''}
                type="date"
                variant="standard"
                InputProps={{
                  inputProps: { max: new Date().toISOString().substring(0, 10) }
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('notes')}
                fullWidth
                label="Notes"
                margin="dense"
                name="notes"
                onChange={handleChange}
                placeholder="Notes"
                multiline
                rows={3}
                value={formState.values.notes || ''}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!formState.isValid || isRecordingPayment}
            onClick={handleCreateBill}
            variant="contained">
            {!isRecordingPayment ? <>Add Payment</> : <>Adding Payment</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PaymentsToolbar.propTypes = {
  className: PropTypes.string
};

export default PaymentsToolbar;
