import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { Print } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Grid,
  Box
} from '@material-ui/core';
import EdiText from 'react-editext';
import BillAmountModal from './BillAmountModal';
import BillDeliveryModal from './BillDelieveryModal';
import BillRejectModal from './BillRejectModal';
import BillReturnModal from './BillReturnModal';
import Select from 'react-select';

import { statusMappings } from '../../mappings';

const useStyles = makeStyles(() => ({
  cardBody: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  },
  editButton: {
    padding: 3,
    background: 'none',
    border: '1px solid'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const mobileContainsFiels = {
  sim: 'SIM',
  simTray: 'SIM Tray',
  battery: 'Battery',
  backdoor: 'Back Door',
  pouch: 'Pouch',
  otherItems: 'Other Items'
};

const mobileContainsFielsOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

const BillDetails = props => {
  const {
    className,
    billDetail,
    updateBillDetail,
    getBillDetail,
    getBillHistory,
    ...rest
  } = props;

  const classes = useStyles();
  const componentRef = useRef();

  const userDetails = useSelector(state => state.UserReducer.user);
  const [currentTime, setCurrentTime] = useState(moment());

  const [billStatus, setBillStatus] = React.useState(billDetail.currentStatus);

  const [openBillAmountModal, setOpenBillAmountModal] = React.useState(false);

  const [openBillDeliveryModal, setOpenBillDeliveryModal] = React.useState(
    false
  );

  const [openBillRejectionModal, setOpenBillRejectionModal] = React.useState(
    false
  );

  const [openBillReturnModal, setOpenBillReturnModal] = React.useState(false);

  const handleUpdate = type => {
    setOpenBillAmountModal(false);
    setOpenBillDeliveryModal(false);
    setOpenBillRejectionModal(false);
    setOpenBillReturnModal(false);
    if (type != 'FAILURE') {
      setBillStatus(type === 'DELIVERY' ? 4 : 3);
      getBillHistory();
      getBillDetail();
    }
  };

  const handleStatusChange = value => {
    if (value === 5) {
      setOpenBillRejectionModal(true);
    } else if (value === 6) {
      setOpenBillReturnModal(true);
    } else if (value === 3) {
      setOpenBillAmountModal(true);
    } else if (value === 4) {
      setOpenBillDeliveryModal(true);
    } else {
      setBillStatus(value);
      updateBillDetail('currentStatus', value);
    }
  };

  React.useEffect(() => {
    setBillStatus(billDetail.currentStatus);
  }, [billDetail.currentStatus]);

  return (
    <>
      {openBillRejectionModal && (
        <BillRejectModal billId={billDetail._id} handleUpdate={handleUpdate} />
      )}
      {openBillAmountModal && (
        <BillAmountModal
          billId={billDetail._id}
          estimatedAmount={billDetail.estimatedAmount}
          handleUpdate={handleUpdate}
        />
      )}
      {openBillDeliveryModal && (
        <BillDeliveryModal
          billId={billDetail._id}
          handleUpdate={handleUpdate}
          customerDetails={billDetail.customer}
        />
      )}
      {openBillReturnModal && (
        <BillReturnModal
          billId={billDetail._id}
          handleUpdate={handleUpdate}
          customerDetails={billDetail.customer}
        />
      )}
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Bill Info" />
        {!billDetail.isLoading && (
          <CardActions className={classes.actions}>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<Print />}>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => setCurrentTime(moment())}
            />
            {billStatus == 2 && (
              <Button
                color="secondary"
                onClick={() => handleStatusChange(5)}
                size="small"
                variant="contained">
                Change to {statusMappings[5]}
              </Button>
            )}
            {billStatus == 5 && (
              <Button
                color="secondary"
                onClick={() => handleStatusChange(6)}
                size="small"
                variant="contained">
                Change to {statusMappings[6]}
              </Button>
            )}
            {billStatus !== 4 && billStatus !== 5 && billStatus !== 6 && (
              <Button
                color="primary"
                onClick={() => handleStatusChange((billStatus || 1) + 1)}
                size="small"
                variant="contained">
                Change to {statusMappings[(billStatus || 1) + 1]}
              </Button>
            )}
          </CardActions>
        )}
        <Divider />
        <CardContent className={classes.cardBody}>
          {billDetail.isLoading ? (
            <div className={classes.loaderStyle}>
              <CircularProgress />
            </div>
          ) : (
            <div
              className="section-to-print"
              ref={componentRef}
              style={{
                backgroundImage: '/images/logos/fine_fix_logo_grey.svg'
              }}>
              <div className="show-only-on-print header">
                <Grid container spacing={1} justify="center">
                  <Grid item md={12} lg={12} xs={12}>
                    <img
                      alt="Logo"
                      className={classes.titleImg}
                      src="/images/logos/fine_fix_logo_grey.svg"
                    />
                    <Box pl={2}>
                      <Typography className={classes.title} noWrap variant="h5">
                        Finefix Technology
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12} lg={12} xs={12}>
                    <div className="no-space">
                      6/277,Post Office, Street, Avinashi, Tamil Nadu 641654
                    </div>
                  </Grid>
                  <Grid item md={12} lg={12} xs={12}>
                    <div className="no-space">
                      <b>Service : <i>+91 99428 39390, +91 96779 39390</i></b>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Table>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '80%' }} />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Customer</TableCell>
                    <TableCell>
                      {billDetail.customer.firstName}{' '}
                      {billDetail.customer.lastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Customer Contact</TableCell>
                    <TableCell>
                      {billDetail.customer.phone}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Bill Number</TableCell>
                    <TableCell>
                      <Typography variant='h6'>{`FFT${String(billDetail.billId).padStart(4, '0')}`}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Bill Date</TableCell>
                    <TableCell>
                      {moment(billDetail.billDate).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                  <TableRow className="not-print">
                    <TableCell variant="head">Created Date</TableCell>
                    <TableCell>
                      {moment(billDetail.createdAt).format(
                        'DD/MM/YYYY hh:mm A'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="not-print">
                    <TableCell variant="head">Reference</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        onSave={val => updateBillDetail('reference', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="text"
                        value={billDetail.reference}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Device Name</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        onSave={val => updateBillDetail('deviceName', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="text"
                        value={billDetail.deviceName}
                        {...((billDetail.deviceType === 'computer' ||
                          billDetail.deviceType === 'mobile') && {
                          validation: val => val.length > 0,
                          validationMessage: 'Device Name cannot be Empty'
                        })}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Device Model</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        onSave={val => updateBillDetail('deviceModel', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="text"
                        value={billDetail.deviceModel}
                        {...((billDetail.deviceType === 'computer' ||
                          billDetail.deviceType === 'mobile') && {
                          validation: val => val.length > 0,
                          validationMessage: 'Device Model cannot be Empty'
                        })}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Device Model Type</TableCell>
                    <TableCell>
                      {billDetail.deviceType === 'mobile' ? (
                        <>
                          <div className="not-print">
                            <RadioGroup
                              row
                              value={billDetail.deviceModelType}
                              onChange={event =>
                                updateBillDetail(
                                  'deviceModelType',
                                  event.target.value
                                )
                              }>
                              {['keypad', 'touch'].map(device => (
                                <FormControlLabel
                                  key={device}
                                  name="deviceModelType"
                                  value={device}
                                  control={<Radio color="primary" />}
                                  label={`${device[0].toUpperCase()}${device.slice(
                                    1
                                  )}`}
                                />
                              ))}
                            </RadioGroup>
                          </div>
                          <div className="show-only-on-print">
                            {billDetail.deviceModelType}
                          </div>
                        </>
                      ) : (
                        <>
                          <EdiText
                            cancelOnEscape
                            cancelOnUnfocus
                            editOnViewClick
                            onSave={val =>
                              updateBillDetail('deviceModelType', val)
                            }
                            showButtonsOnHover
                            submitOnEnter
                            type="text"
                            value={billDetail.deviceModelType}
                          />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Device Type</TableCell>
                    <TableCell>
                      {billDetail.deviceType !== 'cctv'
                        ? `${billDetail.deviceType[0].toUpperCase()}${billDetail.deviceType.slice(
                            1
                          )}`
                        : billDetail.deviceType.toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Defects</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        onSave={val => updateBillDetail('defects', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="text"
                        value={billDetail['defects']}
                        validation={val => val.length > 0}
                        validationMessage="Defects cannot be Empty"
                      />
                    </TableCell>
                  </TableRow>
                  {billDetail.deviceType === 'mobile' && (
                    <>
                      <TableRow>
                        <TableCell variant="head">IMEI</TableCell>
                        <TableCell>
                          <EdiText
                            cancelOnEscape
                            cancelOnUnfocus
                            editOnViewClick
                            onSave={val => updateBillDetail('imei', val)}
                            showButtonsOnHover
                            submitOnEnter
                            type="text"
                            value={billDetail.imei}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">Contains</TableCell>
                        <TableCell>
                          <colgroup>
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '80%' }} />
                          </colgroup>
                          {Object.keys(mobileContainsFiels).map(field => {
                            return (
                              <TableRow>
                                <TableCell>
                                  {mobileContainsFiels[field]}
                                </TableCell>
                                <TableCell>
                                  <>
                                    <div className="not-print">
                                      {field === 'otherItems' ? (
                                        <EdiText
                                          cancelOnEscape
                                          cancelOnUnfocus
                                          editOnViewClick
                                          onSave={val =>
                                            updateBillDetail(field, val)
                                          }
                                          showButtonsOnHover
                                          submitOnEnter
                                          type="text"
                                          value={billDetail[field]}
                                          validation={val => val.length > 0}
                                          validationMessage="Other Items cannot be Empty"
                                        />
                                      ) : (
                                        <Select
                                          value={mobileContainsFielsOptions.filter(
                                            option =>
                                              option.value === billDetail[field]
                                          )}
                                          onChange={option =>
                                            updateBillDetail(
                                              field,
                                              option.value
                                            )
                                          }
                                          options={mobileContainsFielsOptions}
                                        />
                                      )}
                                    </div>
                                    <div className="show-only-on-print">
                                      {billDetail[field]}
                                    </div>
                                  </>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {billDetail.deviceType === 'computer' && (
                    <TableRow>
                      <TableCell variant="head">Contains</TableCell>
                      <TableCell>
                        <EdiText
                          cancelOnEscape
                          cancelOnUnfocus
                          editOnViewClick
                          onSave={val => updateBillDetail('otherItems', val)}
                          showButtonsOnHover
                          submitOnEnter
                          type="textarea"
                          multiline
                          rows={3}
                          value={billDetail.otherItems}
                          validation={val => val.length > 0}
                          validationMessage="Contains cannot be Empty"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell variant="head">Estimated Amount</TableCell>
                    <TableCell>
                      {billStatus === 1 || billStatus === 2 ? (
                        <EdiText
                          cancelOnEscape
                          cancelOnUnfocus
                          editOnViewClick
                          onSave={val =>
                            updateBillDetail('estimatedAmount', val)
                          }
                          showButtonsOnHover
                          submitOnEnter
                          type="number"
                          validation={val =>
                            /^[0-9]+$/.test(val) && parseInt(val) >= 0
                          }
                          validationMessage="Please enter a valid amount"
                          value={billDetail.estimatedAmount.toString()}
                        />
                      ) : (
                        <>{billDetail.estimatedAmount}</>
                      )}
                    </TableCell>
                  </TableRow>
                  {billDetail.hasOwnProperty('billAmount') && (
                    <TableRow>
                      <TableCell variant="head">Bill Amount</TableCell>
                      <TableCell>
                        <EdiText
                          cancelOnEscape
                          cancelOnUnfocus
                          editOnViewClick
                          onSave={val => updateBillDetail('billAmount', val)}
                          showButtonsOnHover
                          submitOnEnter
                          type="text"
                          validation={val => /^[0-9]+$/.test(val)}
                          validationMessage="Please enter only numbers"
                          value={billDetail.billAmount}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell variant="head">Case Summary</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        inputProps={{
                          rows: 5
                        }}
                        onSave={val => updateBillDetail('caseSummary', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="textarea"
                        validation={val => val.length > 0}
                        validationMessage="Case Summary cannot be Empty"
                        value={billDetail.caseSummary}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Engineer Summary</TableCell>
                    <TableCell>
                      <EdiText
                        cancelOnEscape
                        cancelOnUnfocus
                        editOnViewClick
                        inputProps={{
                          rows: 5
                        }}
                        onSave={val => updateBillDetail('engineerSummary', val)}
                        showButtonsOnHover
                        submitOnEnter
                        type="textarea"
                        validation={val => val.length > 0}
                        validationMessage="Engineer Summary cannot be Empty"
                        value={billDetail.engineerSummary}
                      />
                    </TableCell>
                  </TableRow>
                  {billDetail.rejectionComments && (
                    <TableRow className="not-print">
                      <TableCell variant="head">Rejection Comments</TableCell>
                      <TableCell>{billDetail.rejectionComments}</TableCell>
                    </TableRow>
                  )}
                  <TableRow className="not-print">
                    <TableCell variant="head">Current Status</TableCell>
                    <TableCell>{statusMappings[billStatus || 1]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className="show-only-on-print footer">
                <Grid container>
                  <Grid item md={6} lg={6} xs={6} container spacing={1}>
                    <Grid item md={12} lg={12} xs={12} className="left">
                      <Typography style={{fontWeight: 'bold'}}>Date: </Typography> {' '}
                      <Typography style={{ paddingLeft: 4 }}>{moment(currentTime).format('DD/MM/YYYY')}</Typography>
                    </Grid>
                    <Grid item md={12} lg={12} xs={12} className="left">
                    <Typography style={{ fontWeight: 'bold'}}>Time: </Typography> {' '}
                    <Typography style={{ paddingLeft: 4 }}>{moment(currentTime).format('hh:mm A')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item md={6} lg={6} xs={6} container> 
                    <Grid item md={12} lg={12} xs={12} className="right">
                    <Typography variant="h6">Engineer Sign ( {userDetails.firstName} {userDetails.lastName})</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default BillDetails;
