import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  CircularProgress,
  TableHead
} from '@material-ui/core';
import { billVarsMappings, statusMappings } from '../../mappings';

const useStyles = makeStyles(() => ({
  cardBody: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  }
}));

const getHistoryTimeValue = time => {
  const now = moment();
  const prevTime = moment(time);
  const minutes = now.diff(prevTime, 'minutes');
  const hours = now.diff(prevTime, 'hours');
  const days = now.diff(prevTime, 'days');
  const weeks = now.diff(prevTime, 'weeks');
  let text;
  if (minutes < 60) {
    text = `${minutes} minutes`;
  } else if (hours < 24) {
    text = `${hours} hours`;
  } else if (days < 7) {
    text = `${days} days`;
  } else {
    text = `${weeks} weeks`;
  }
  return `${text} ago - ${moment(time).format('DD/MM/YYYY HH:mm A')} `;
};

const getHistoryText = (history, currentDiff, position) => {
  const diffArray = history.diff[currentDiff];
  const value =
    diffArray.length === 1 && position === 0
      ? '-'
      : diffArray.length === 1 && position === 1
      ? diffArray[0]
      : diffArray[position];
  if (currentDiff === 'currentStatus') {
    return statusMappings[value];
  }
  if (currentDiff === 'servicePartItems' && value != '-' && value) {
    return value.map(v => `${v.itemName} - ${v.itemPrice}`).join(', ');
  }
  return value;
};

const BillHistory = props => {
  const { className, billHistory, deviceType, ...rest } = props;

  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Bill History" />
      <Divider />
      <CardContent className={classes.cardBody}>
        {billHistory.isLoading ? (
          <div className={classes.loaderStyle}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <TableBody>
              {billHistory.history.map(history => (
                <TableRow key={history.version}>
                  <TableCell>
                    {history.user.firstName} {history.user.lastName}{' '}
                    {history.event === 'created'
                      ? 'created this bill'
                      : 'made changes'}
                    {' - '}
                    {getHistoryTimeValue(history.timestamp)}
                    {history.event === 'updated' && (
                      <>
                        {
                          <Table>
                            <colgroup>
                              <col style={{ width: '20%' }} />
                              <col style={{ width: '40%' }} />
                              <col style={{ width: '40%' }} />
                            </colgroup>
                            <TableBody>
                              <TableRow>
                                <TableCell variant="head">Field</TableCell>
                                <TableCell variant="head">
                                  Original Value
                                </TableCell>
                                <TableCell variant="head">New Value</TableCell>
                              </TableRow>
                              {Object.keys(history.diff).map(currentDiff => (
                                <TableRow key={currentDiff}>
                                  <TableCell>
                                    {
                                      billVarsMappings[
                                        `${currentDiff}${
                                          deviceType === 'computer' &&
                                          currentDiff === 'otherItems'
                                            ? deviceType
                                            : ''
                                        }`
                                      ]
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {getHistoryText(history, currentDiff, 0)}
                                  </TableCell>
                                  <TableCell>
                                    {getHistoryText(history, currentDiff, 1)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        }
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default BillHistory;
