import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';

const schema = {
  rejectionComments: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  }
}));

const BillRejectModal = props => {
  const dispatch = useDispatch();
  const { className, customerDetails, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    setFormState({
      touched: {},
      values: {},
      errors: {}
    })
  };

  const [formState, setFormState] = useState({
    rejectionComments: '',
    touched: {},
    values: {
    },
    errors: {}
  });


  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isUpdatingBillRejection, setIsUpdatingBillRejection] = React.useState(false);


  const handleUpdateBillRejectionDetails = async () => {
    const { rejectionComments } = formState.values;
    const formData = {
      currentStatus: 5,
      rejectionComments
    }
    setIsUpdatingBillRejection(true);
    try {
      await axios.put(`/api/updateBill/${props.billId}`, formData);
      dispatch(showSnack('UPDATE_BILL_DETAILS', {
        button: { label: 'OKAY' },
        label: 'Updated Successfully',
        timeout: 2000
      }));
      props.handleUpdate();
    } catch (e) {
      setIsUpdatingBillRejection(false);
      console.log('error', e);
      dispatch(showSnack('UPDATE_BILL_DETAILS_ERROR', {
        label: 'Something Went Wrong..!',
        timeout: 3000
      }));

    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        onClose={handleClose}
        open
      >
        <DialogTitle className={classes.modalHeader}>Reject Bill</DialogTitle>
        <DialogContent>
          <>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={hasError('rejectionComments')}
                  fullWidth
                  label="Bill Rejection Details"
                  margin="dense"
                  name="rejectionComments"
                  onChange={handleChange}
                  placeholder="Bill Rejection Details"
                  required
                  multiline
                  rows={4}
                  value={formState.values.rejectionComments || ''}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isUpdatingBillRejection || !formState.isValid}
            onClick={handleUpdateBillRejectionDetails}
            variant="contained"
          >
            {!isUpdatingBillRejection ? <>Update Rejection Details</> : <>Updating</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

BillRejectModal.propTypes = {
  className: PropTypes.string
};

export default BillRejectModal;
