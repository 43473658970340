import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Box, RadioGroup, Radio, FormControlLabel, Typography, FormLabel, FormControl, FormGroup, Checkbox, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import moment from 'moment';

const promiseOptions = inputValue =>
  new Promise((resolve, reject) => {
    const url = `/api/getCustomersByNameOrPhone/${inputValue}`;
    axios.get(url)
      .then(result => {
        const options = result.data.map(option => {
          return {
            label: `${option.firstName} ${option.lastName}`, value: option.id,
            customerDetails: { phone: option.phone, email: option.email }
          };
        })
        resolve(options);
      })
  });

const schema = {
  customerId: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  deviceType: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  caseSummary: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  engineerSummary: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  defects: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  estimatedAmount: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0
    }
  },
  billDate: {
    datetime: {
      dateOnly: true,
      latest: moment.utc()
    }
  }
};

validate.extend(validate.validators.datetime, {
  parse: function (value, options) {
    return new Date(value).valueOf()
  },
  format: function (value, options) {
    return new Date(value)
  }
})

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const fieldNameMappings = {
  customerId: 'Customer Name',
  engineerSummary: 'Engineer Summary',
  caseSummary: 'Case Summary',
  estimatedAmount: 'Estimated Amount',
  defects: 'Defects',
  billDate: 'Bill Date'
}

const BillsToolbar = props => {
  const dispatch = useDispatch();
  const { className, refreshTableData, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormState({
      isValid: false,
      touched: {},
      values: { deviceType: 'computer', imeiStatus: 'no', battery: false, imei: '', pouch: false, backdoor: false, sim: false, simTray: false, billDate: new Date().toISOString().substring(0, 10) },
      errors: {}
    })
    setCreatingBill(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    values: { deviceType: 'computer', imeiStatus: 'no', battery: false, imei: '', pouch: false, backdoor: false, sim: false, simTray: false, billDate: new Date().toISOString().substring(0, 10) },
    errors: {},
    errorMessage: ''
  });
  useEffect(() => {
    const { deviceType, imeiStatus, imei, deviceName, deviceModel, otherItemsAvailable, otherItems, billDate } = formState.values;
    let isValid = true;
    const errors = validate(formState.values, schema);
    let errorFields = []
    if (deviceType === 'mobile') {
      if (imeiStatus === 'yes' && !imei) {
        isValid = false;
        errorFields.push('IMEI Number');
      }
      if (otherItemsAvailable && !otherItems) {
        isValid = false;
        errorFields.push('Other Items');
      }
      if (!deviceName) {
        isValid = false;
        errorFields.push('Device Name');
      }
      if (!deviceModel) {
        isValid = false;
        errorFields.push('Device Model');
      }
    } else if (deviceType === 'computer') {
      if (!deviceName) {
        isValid = false;
        errorFields.push('Device Name');
      }
      if (!deviceModel) {
        isValid = false;
        errorFields.push('Device Model');
      }
      if (!otherItems) {
        isValid = false;
        errorFields.push('Contains');
      }
    } else {

    }

    if (errors && (errors['caseSummary'] || errors['engineerSummary'] || errors['estimatedAmount'] || errors['customerId'] || errors['defects'] || errors['billDate'])) {
      Object.keys(errors).forEach(error => {
        const val = fieldNameMappings[error];
        if (val) {
          errorFields.push(val)
        }
      })
      isValid = false;
    }
    const errorMessage = errorFields.length ? `Please enter valid values for ${errorFields.join(', ')}` : '';
    setFormState(formState => ({
      ...formState,
      isValid,
      errors: errors || {},
      errorMessage
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleCustomerSelect = option => {
    console.log(option);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        customerId: option ? option.value : '',
        customerDetails: option ? option.customerDetails : ''
      },
      touched: {
        ...formState.touched,
        customerId: true
      }
    }));
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [iscreatingBill, setCreatingBill] = React.useState(false);

  const handleCreateBill = async () => {
    const { customerId, deviceType, caseSummary, engineerSummary, estimatedAmount, defects, imeiStatus, imei,
      sim, simTray, battery, pouch, backdoor, deviceModelType, billDate,
      deviceName, deviceModel, otherItemsAvailable, otherItems, reference } = formState.values;

    const formData = {
      reference,
      customerId,
      deviceType,
      caseSummary,
      engineerSummary,
      estimatedAmount,
      defects,
      ...(deviceType === 'mobile' && {
        imei: imeiStatus ? imei : '',
        sim: sim ? 'yes' : 'no',
        simTray: simTray ? 'yes' : 'no',
        battery: battery ? 'yes' : 'no',
        pouch: pouch ? 'yes' : 'no',
        backdoor: backdoor ? 'yes' : 'no',
        otherItems: otherItemsAvailable ? otherItems : ''
      }),
      ...(deviceType === 'computer' && {
        otherItems,
      }),
      deviceName,
      deviceModel,
      deviceModelType,
      billDate: new Date(billDate)
    }
    try {
      setCreatingBill(true);
      const url = '/api/createBill';
      const res = await axios.post(url, formData);
      refreshTableData();
      const billRef = `FFT${String(res.data.billId).padStart(4, '0')}`
      dispatch(showSnack('CREATE_BILL_SUCCESS', {
        label: `Successfully created Bill ${billRef}..!!`,
        timeout: 3000
      }));
      handleClose();
    } catch (e) {
      setCreatingBill(false);
      console.log('error', e);

      dispatch(showSnack('CREATE_BILL_ERROR', {
        label: 'Something went wrong..!',
        timeout: 3000
      }));

    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          onClick={handleClickOpen}
          variant="contained"
        >
          Create Bill
        </Button>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle className={classes.modalHeader}>Create New Bill</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>Customer</Typography>
              <Box py={1}>
                <div style={{ border: hasError('customerId') ? '1px solid red' : '' }}>
                  <AsyncSelect
                    backspaceRemovesValue={true}
                    name="customerId"
                    isClearable={true}
                    defaultOptions={
                      [
                        { label: 'Type to Search', isDisabled: true, value: '-1' }
                      ]
                    }
                    onChange={handleCustomerSelect}
                    noOptionsMessage={() => <>No Customers Found</>}
                    placeholder={<div>Type to search</div>}
                    loadOptions={debounce(promiseOptions, 500)} />
                </div>
              </Box>
            </Grid>
            {
              formState.values.customerDetails &&
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography>Customer Details</Typography>
                <Box py={1}>
                  <Typography>Phone: {formState.values.customerDetails.phone}</Typography>
                  {
                    formState.values.customerDetails.email &&
                    <Typography>Email: {formState.values.customerDetails.email}</Typography>
                  }
                </Box>
              </Grid>
            }
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('billDate')}
                fullWidth
                label="Bill Date"
                margin="dense"
                format="DD-MM-YYYY"
                name="billDate"
                onChange={handleChange}
                placeholder="Bill Date"
                required
                defaultValue={new Date().toISOString().substring(0, 10)}
                value={formState.values.billDate || ''}
                type="date"
                variant="standard"
                InputProps={{ inputProps: { max: new Date().toISOString().substring(0, 10) } }}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('reference')}
                fullWidth
                label="Reference"
                margin="dense"
                name="reference"
                onChange={handleChange}
                placeholder="Reference"
                value={formState.values.reference || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>Device Type</Typography>
              <RadioGroup row value={formState.values.deviceType} onChange={handleChange}>
                {
                  ['computer', 'cctv', 'mobile', 'others'].map(device => (
                    <FormControlLabel
                      key={device}
                      name="deviceType"
                      value={device}
                      control={
                        <Radio color="primary" />
                      }
                      label={
                        device !== 'cctv' ?
                          `${device[0].toUpperCase()}${device.slice(1)}`
                          : device.toUpperCase()
                      }

                    />
                  ))
                }
              </RadioGroup>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('deviceName')}
                fullWidth
                label="Device Name"
                margin="dense"
                name="deviceName"
                onChange={handleChange}
                placeholder="Device Name"
                {...((formState.values.deviceType === 'computer' || formState.values.deviceType === 'mobile') && { required: true })}
                value={formState.values.deviceName || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('deviceModel')}
                fullWidth
                label="Device Model"
                margin="dense"
                name="deviceModel"
                onChange={handleChange}
                placeholder="Device Model"
                {...((formState.values.deviceType === 'computer' || formState.values.deviceType === 'mobile') && { required: true })}
                value={formState.values.deviceModel || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              {
                formState.values.deviceType === 'mobile' ?
                  <>
                    <Typography>Device Model Type</Typography>
                    <RadioGroup row value={formState.values.deviceModelType} onChange={handleChange}>
                      {
                        ['keypad', 'touch'].map(device => (
                          <FormControlLabel
                            name="deviceModelType"
                            value={device}
                            control={
                              <Radio color="primary" />
                            }
                            label={`${device[0].toUpperCase()}${device.slice(1)}`}

                          />
                        ))
                      }
                    </RadioGroup>
                  </>
                  :
                  <>
                    <TextField
                      error={hasError('deviceModelType')}
                      fullWidth
                      label="Device Model Type"
                      margin="dense"
                      name="deviceModelType"
                      onChange={handleChange}
                      placeholder="Device Model Type"
                      value={formState.values.deviceModelType || ''}
                      variant="standard"
                    />
                  </>
              }
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('defects')}
                fullWidth
                label="Defects"
                margin="dense"
                name="defects"
                onChange={handleChange}
                placeholder="Defects"
                multiline
                rows={4}
                required
                value={formState.values.defects || ''}
                variant="standard"
              />
            </Grid>
            {formState.values.deviceType === 'mobile' &&
              <>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Typography>IMEI</Typography>
                  <RadioGroup row value={formState.values.imeiStatus} onChange={handleChange}>
                    {
                      ['yes', 'no'].map(imeiStatus => (
                        <FormControlLabel
                          name="imeiStatus"
                          value={imeiStatus}
                          control={
                            <Radio color="primary" />
                          }
                          label={`${imeiStatus !== 'yes' ? 'Not ' : ''} Available`}

                        />
                      ))
                    }
                  </RadioGroup>
                  {formState.values.imeiStatus === 'yes' &&
                    <TextField
                      error={hasError('imei')}
                      fullWidth
                      label="IMEI Number"
                      margin="dense"
                      name="imei"
                      onChange={handleChange}
                      placeholder="IMEI Number"
                      required
                      value={formState.values.imei || ''}
                      variant="standard"
                    />
                  }
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Contains</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.sim} onChange={handleChange} name="sim" />}
                        label="Sim"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.simTray} onChange={handleChange} name="simTray" />}
                        label="Sim Tray"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.battery} onChange={handleChange} name="battery" />}
                        label="Battery"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.pouch} onChange={handleChange} name="pouch" />}
                        label="Pouch"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.backdoor} onChange={handleChange} name="backdoor" />}
                        label="Back Door"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={formState.values.otherItemsAvailable} onChange={handleChange} name="otherItemsAvailable" />}
                        label="Other Items"
                      />
                      {formState.values.otherItemsAvailable &&
                        <TextField
                          error={hasError('otherItems')}
                          fullWidth
                          hiddenLabel
                          margin="dense"
                          name="otherItems"
                          onChange={handleChange}
                          placeholder="Other Items"
                          required
                          value={formState.values.otherItems || ''}
                          variant="standard"
                        />
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
              </>
            }
            {
              formState.values.deviceType === 'computer' &&
              < Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={hasError('otherItems')}
                  fullWidth
                  label="Contains"
                  margin="dense"
                  name="otherItems"
                  onChange={handleChange}
                  placeholder="Other Items"
                  required
                  multiline
                  rows={3}
                  value={formState.values.otherItems || ''}
                  variant="standard"
                />
              </Grid>
            }
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('caseSummary')}
                fullWidth
                label="Case Summary"
                margin="dense"
                name="caseSummary"
                onChange={handleChange}
                placeholder="Case Summary"
                multiline
                rows={4}
                required
                value={formState.values.caseSummary || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('engineerSummary')}
                fullWidth
                label="Engineer Summary"
                margin="dense"
                name="engineerSummary"
                onChange={handleChange}
                placeholder="Engineer Summary"
                multiline
                rows={4}
                required
                value={formState.values.engineerSummary || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('estimatedAmount')}
                fullWidth
                label="Estimated Amount"
                margin="dense"
                name="estimatedAmount"
                onChange={handleChange}
                placeholder="Estimated Amount"
                required
                value={formState.values.estimatedAmount || ''}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Tooltip title={!iscreatingBill ? formState.errorMessage : ''} placement="top">
            <span>
              <Button
                color="primary"
                disabled={!formState.isValid || iscreatingBill}
                onClick={handleCreateBill}
                variant="contained"
              >
                {!iscreatingBill ? <>Create Bill</> : <>Creating Bill</>}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div >
  );
};

BillsToolbar.propTypes = {
  className: PropTypes.string
};

export default BillsToolbar;
