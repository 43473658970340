import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  Account as AccountView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Customers as CustomersView,
  CustomersEdit as CustomersEditView,
  BillsList as BillsListView,
  BillEdit as BillEditView,
  PaymentsList as PaymentsList,
  Reports as ReportsView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <Route
        component={SignInView}
        exact
        path="/sign-in"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        restrictRoles={['ROLE_SUPER_ADMIN']}
        path="/users"
      />
      <RouteWithLayout
        component={CustomersView}
        exact
        layout={MainLayout}
        path="/customers"
      />
      <RouteWithLayout
        component={CustomersEditView}
        exact
        layout={MainLayout}
        path="/customers/:id"
      />
      <RouteWithLayout
        component={BillsListView}
        exact
        layout={MainLayout}
        path="/bills"
      />
      <RouteWithLayout
        component={PaymentsList}
        exact
        layout={MainLayout}
        path="/payments"
      />
      <RouteWithLayout
        component={BillEditView}
        exact
        layout={MainLayout}
        path="/bill/:id"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        restrictRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
        path="/reports"
      />
      {/* <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      /> */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
