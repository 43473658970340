import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Grid, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
// import mockData from './data';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomerDetails from './components/CustomerDetails';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center'
  }
}));

const CustomerList = props => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(
    state => state.UserReducer.isValidatedFromState
  );

  const [customerDetail, setCustomerDetail] = React.useState({
    isLoading: true
  });

  useEffect(() => {
    if (!isValidatedFromState && isLoggedIn) {
      axios
        .get(`/api/getCustomerDetails/${id}`)
        .then(res => {
          setCustomerDetail({ isLoading: false, ...res.data });
        })
        .catch(error => {
          console.log('Error', error);
        });
    }
  }, [dispatch, isLoggedIn, isValidatedFromState]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h3">
            Customer Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={() => history.goBack()}
            size="small"
            variant="contained"
            startIcon={<ArrowBack />}>
            Back to previous Page
          </Button>
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomerDetails
            isLoading={customerDetail.isLoading}
            customerDetails={customerDetail}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerList;
