import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import BillDetails from './components/BillDetails';
import BillHistory from './components/BillHistory';
import axios from 'axios';
import { showSnack } from 'react-redux-snackbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center'
  }
}));

const BillEdit = props => {
  const {
    match: {
      params: { id }
    }
  } = props;

  const [billDetail, setBillDetail] = React.useState({
    isLoading: true
  });

  const [billHistory, setBillHistory] = React.useState({
    isLoading: true,
    history: []
  });

  const history = useHistory();

  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(
    state => state.UserReducer.isValidatedFromState
  );
  useEffect(() => {
    if (!isValidatedFromState && isLoggedIn) {
      axios
        .get(`/api/billDetails/${id}`)
        .then(res => {
          setBillDetail({ isLoading: false, ...res.data });
        })
        .catch(error => {
          console.log('Error', error);
        });
      getBillHistory();
    }
  }, [dispatch, isLoggedIn, isValidatedFromState]);

  const getBillHistory = () => {
    axios
      .get(`/api/billHistory/${id}`)
      .then(res => {
        setBillHistory({ isLoading: false, history: res.data });
      })
      .catch(error => {
        console.log('Error', error);
      });
  };

  const getBillDetail = () => {
    axios
      .get(`/api/billDetails/${id}`)
      .then(res => {
        setBillDetail({ isLoading: false, ...res.data });
      })
      .catch(error => {
        console.log('Error', error);
      });
  };

  const updateBillDetail = (prop, value) => {
    setBillDetail({ ...billDetail, [prop]: value });
    axios
      .put(`/api/updateBill/${id}`, { [prop]: value })
      .then(res => {
        getBillHistory();
        dispatch(
          showSnack('UPDATE_BILL_DETAILS', {
            button: { label: 'OKAY' },
            label: 'Updated Successfully',
            timeout: 2000
          })
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(
          showSnack('myUniqueId', {
            button: { label: 'OKAY' },
            label: 'Error in Saving Changes',
            timeout: 3000
          })
        );
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h3">
            Bill Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={() => history.goBack()}
            size="small"
            variant="contained"
            startIcon={<ArrowBack />}>
            Back to previous Page
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <BillDetails
            billDetail={billDetail}
            getBillDetail={getBillDetail}
            updateBillDetail={updateBillDetail}
            getBillHistory={getBillHistory}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <BillHistory
            billHistory={billHistory}
            deviceType={billDetail.deviceType}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BillEdit;
