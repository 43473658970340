import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: 'green',
        fontWeight: 'bold'
    },
    title: {
        color: '#fff',
        paddingLeft: theme.spacing(2)
    },
    titleImg: {
        height: 40
    }
}));

const Topbar = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            position="fixed"
        >
            <Toolbar className={clsx(classes.root, className)}>
                <RouterLink to="/">
                    <img
                        alt="Logo"
                        className={classes.titleImg}
                        src="/images/logos/fine_fix_logo_grey.svg"
                    />
                </RouterLink>
                <Typography className={classes.title} noWrap variant="h5" >
                    Finefix Technology
        </Typography>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string
};

export default Topbar;
