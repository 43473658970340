import axios from 'axios';
import { dispatch } from '../helpers/store-helper';
import { showSnack } from 'react-redux-snackbar';

export const setUpAxiosConfig = (url, token) => {
  axios.defaults.baseURL = url;
  axios.defaults.headers.common['x-access-token'] = token;
  axios.interceptors.response.use(undefined, error => {
    const { status } = error.response;
    if(status === 401) {
      dispatch({ type: 'LOGIN_FAILURE' });
      localStorage.clear();
      dispatch(showSnack('myUniqueId', {
        button: { label: 'GOT IT' },
        label: 'Please Login Back !',
        timeout: 3000
      }));
    }
    return Promise.reject({ ...error })
  })
};
