import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const mappings = {
  mobile: 'Mobile',
  computer: 'Computer',
  cctv: 'CCTV',
  others: 'Others'
};


const icons = {
  'Mobile': <PhoneIphoneIcon />,
  'Computer': <LaptopMacIcon />,
  'CCTV': <LiveTvIcon />,
  'Others': <DevicesOtherIcon />
};

const BillsByDevice = props => {
  const {
    className,
    data: billsByDeviceData,
    totalBillsCount,
    ...rest
  } = props;

  let billsData = {};

  billsByDeviceData.forEach(device => {
    billsData[mappings[device.deviceType]] = device.count;
  });

  const classes = useStyles();
  const theme = useTheme();

  const labels = Object.values(mappings);
  const colors = [
    theme.palette.primary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main
  ];

  const data = {
    datasets: [
      {
        data: labels.map(label => billsData[label] / totalBillsCount * 100),
        backgroundColor: colors,
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  
  const devices = labels.map((label,index) => ({
    title: label,
    value: billsData[label],
    icon: icons[label],
    color: colors[index]
  }))
  console.log('billsData', billsData, devices, labels)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Bills By Device So Far" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div className={classes.device} key={device.title}>
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h2">
                {device.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

BillsByDevice.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  totalBillsCount: PropTypes.number
};

export default BillsByDevice;
