import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch } from 'react-redux';
import { logOut } from '../../../../views/SignIn/actions';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#525454',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  title: {
    color: '#fff',
    paddingLeft: theme.spacing(2)
  },
  titleImg: {
    height: 40
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const logout = () => {
    localStorage.clear();
    dispatch(logOut());
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.titleImg}
            src="/images/logos/fine_fix_logo_grey.svg"
          />
        </RouterLink>
        <Typography className={classes.title} noWrap variant="h5" >
          Finefix Technology
        </Typography>
        <div className={classes.flexGrow} />
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={logout}
        >
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
