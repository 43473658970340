import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class RouteWithLayout extends Component {

  render() {  
    const { layout: Layout, component: Component, isLoggedIn, restrictRoles, user, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => {
          if(!isLoggedIn) {
            return <Redirect to="/sign-in" />;
          }
          if(restrictRoles && restrictRoles.indexOf(user.roles[0]) === -1) {
            return <Redirect to="/" />;
          }
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }}
      />
    );
  }
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string 
};

const mapStateToProps = ({
  UserReducer: { isLoggedIn, user }
}) => ({
  isLoggedIn,
  user
});

export default connect(mapStateToProps)(RouteWithLayout);

