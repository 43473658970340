import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import moment from 'moment';

const schema = {
  pendingAmount: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  }
}));

const BillDelieveryModal = props => {
  const dispatch = useDispatch();
  const { className, customerDetails, ...rest } = props;

  const classes = useStyles();

  // const handleClose = () => {
  //   setFormState({
  //     isValid: false,
  //     touched: {},
  //     values: {},
  //     errors: {}
  //   });
  // };

  const [formState, setFormState] = useState({
    isLoading: true,
    pendingAmount: 0,
    isValid: false,
    touched: {},
    values: {
      receivedBy: 'customer'
    },
    errors: {}
  });

  useEffect(() => {
    axios
      .get(`/api/getPendingPayments/${customerDetails._id}`)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          isLoading: false,
          isPaymentPending: res.data.pendingAmount > 0,
          values: {
            ...formState.values,
            pendingAmount: res.data.pendingAmount
          }
        }));
      })
      .catch(error => {
        console.log('Error', error);
      });
  }, []);

  useEffect(() => {
    const {
      receivedBy,
      receievedByPerson,
      pendingAmount: pending
    } = formState.values;
    const pendingAmount = Number(pending);
    let isValid = true;

    if (receivedBy === 'others' && !receievedByPerson) {
      isValid = false;
    }

    if (
      (formState.isPaymentPending && pendingAmount <= 0) ||
      (formState.isPaymentPending && !Number.isInteger(pendingAmount))
    ) {
      isValid = false;
    }

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isUpdatingBillAmount, setUpdateBillAmount] = React.useState(false);

  const handleUpdateBillAmount = async () => {
    const {
      pendingAmount,
      receivedBy: receivedByType,
      receievedByPerson
    } = formState.values;
    const receivedBy =
      receivedByType === 'customer'
        ? `${customerDetails.firstName} ${customerDetails.lastName}`
        : receievedByPerson;
    const formData = {
      currentStatus: 4,
      receivedBy
    };
    setUpdateBillAmount(true);
    if (formState.isPaymentPending) {
      try {
        const paymentData = {
          customerId: customerDetails._id,
          receivedAmount: pendingAmount,
          receivedAt: moment.utc()
        };
        const url = '/api/addPayment';
        await axios.post(url, paymentData);
      } catch (e) {
        console.log('error', e);
        setUpdateBillAmount(false);
        dispatch(
          showSnack('RECORD_PAYMENT_ERROR', {
            label: 'Failed to add payment',
            timeout: 3000
          })
        );
        props.handleUpdate('FAILURE');
        return;
      }
    }
    try {
      await axios.put(`/api/updateBill/${props.billId}`, formData);
      dispatch(
        showSnack('UPDATE_BILL_DETAILS', {
          button: { label: 'OKAY' },
          label: 'Updated Successfully',
          timeout: 2000
        })
      );
      props.handleUpdate('DELIVERY');
    } catch (e) {
      setUpdateBillAmount(false);
      console.log('error', e);
      dispatch(
        showSnack('UPDATE_BILL_AMOUNT_ERROR', {
          label: 'Payment Added..But status update failed..!',
          timeout: 3000
        })
      );
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog maxWidth={'sm'} onClose={() => {}} open>
        <DialogTitle className={classes.modalHeader}>Bill Delivery</DialogTitle>
        <DialogContent>
          {formState.isLoading ? (
            <div className={classes.loaderStyle}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography>Received By</Typography>
                  <RadioGroup
                    row
                    value={formState.values.receivedBy}
                    onChange={handleChange}>
                    <FormControlLabel
                      name="receivedBy"
                      value="customer"
                      control={<Radio color="primary" />}
                      label={`${customerDetails.firstName} ${customerDetails.lastName} (Customer)`}
                    />
                    <FormControlLabel
                      name="receivedBy"
                      value="others"
                      control={<Radio color="primary" />}
                      label="Others"
                    />
                  </RadioGroup>
                </Grid>
                {formState.values.receivedBy === 'others' && (
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={hasError('receievedByPerson')}
                      fullWidth
                      label="Received By"
                      margin="dense"
                      name="receievedByPerson"
                      onChange={handleChange}
                      placeholder="Received By"
                      required
                      value={formState.values.receievedByPerson || ''}
                      variant="standard"
                    />
                  </Grid>
                )}

                {formState.isPaymentPending && (
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={hasError('pendingAmount')}
                      fullWidth
                      label="Amount Received"
                      margin="dense"
                      name="pendingAmount"
                      onChange={handleChange}
                      placeholder="Amount Received"
                      required
                      value={formState.values.pendingAmount || ''}
                      variant="standard"
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={
              !formState.isValid || isUpdatingBillAmount || formState.isLoading
            }
            onClick={handleUpdateBillAmount}
            variant="contained">
            {!isUpdatingBillAmount ? (
              <>Update Delivery Details</>
            ) : (
              <>Updating</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BillDelieveryModal.propTypes = {
  className: PropTypes.string
};

export default BillDelieveryModal;
