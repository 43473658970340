import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_STARTED, LOGIN_FAILURE, LOG_OUT } from '../constants';
import { setUpAxiosConfig } from '../../../helpers/network-utils';
import { showSnack } from 'react-redux-snackbar';
import { dispatch, getAccessToken } from '../../../helpers/store-helper';

const API_URL = process.env.REACT_APP_API_URL;

export const loginSuccess = data => ({
  payload: {
    data
  },
  type: LOGIN_SUCCESS
});

export const loginStarted = () => ({ type: LOGIN_STARTED });

export const loginFailure = () => ({ type: LOGIN_FAILURE });

export const logOut = () => ({ type: LOG_OUT });


export const loginAction = (username, password) => async () => {
  const url = `${API_URL}/api/auth/signin`;
  try {
    dispatch(loginStarted());
    const request = await axios.post(url, { password, username });
    const { data } = request;
    setUpAxiosConfig(API_URL, data.accessToken);
    dispatch(loginSuccess(data));
    dispatch(showSnack('myUniqueId', {
      button: { label: 'GOT IT' },
      label: `Welcome back ${data.firstName} ${data.lastName} !`,
      timeout: 3000
    }));
    localStorage.setItem('user', JSON.stringify(data));
    // history.push('/');
  } catch (e) {
    console.log('error', e);
    let errorText;
    if (e.response && e.response.status) {
      switch (e.response.status) {
        case 404:
          errorText = 'User Do not Exist';
          break;
        case 401:
          errorText = 'Please Check your Password';
          break;
        default:
          errorText = 'Something went wrong';
      }
      dispatch(loginFailure());
      dispatch(showSnack('myUniqueId', {
        button: { label: 'OKAY' },
        label: errorText,
        timeout: 3000
      }));
    }
  }
}

export const validateUser = () => async () => {
  const url = '/api/auth/validateuser';
  const token = getAccessToken();
  setUpAxiosConfig(API_URL, token);
  try {
    const request = await axios.get(url);
    const { data } = request;
    setUpAxiosConfig(API_URL, data.accessToken);
    dispatch(loginSuccess(data));
    dispatch(showSnack('myUniqueId', {
      button: { label: 'GOT IT' },
      label: `Welcome back ${data.firstName} ${data.lastName} !`,
      timeout: 3000
    }));
  } catch (e) {
    dispatch(showSnack('myUniqueId', {
      button: { label: 'GOT IT' },
      label: 'Please Login Back !',
      timeout: 3000
    }));
    localStorage.clear();
    dispatch(loginFailure());
  }
}