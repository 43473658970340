import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from './tableIcons';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

const PaymentsTable = props => {
  const tableRef = props.tableRef;
  return (
    <MaterialTable
      tableRef={tableRef}
      title="All Payments"
      columns={
        [
          {
            title: 'Customer Name', field: 'customerName', editable: 'never', render: rowData => {
              return (
                <>
                  {rowData.customer.firstName} {rowData.customer.lastName}
                </>
              )
            }
          },
          {
            title: 'Received Amount', field: 'receivedAmount', render: rowData => {
              return (
                <>
                  {rowData.receivedAmount}
                </>
              )
            }
          },
          {
            title: 'Notes', field: 'notes', render: rowData => {
              return (
                <>
                  {rowData.notes || '-'}
                </>
              )
            }
          },
          {
            title: 'Received Date', field: 'receivedAt', type: 'date', format: 'DD/MM/YYYY', render: rowData => {
              return (
                <>
                  {moment(rowData.receivedAt).format('DD/MM/YYYY')}
                </>
              )
            }
          },
        ]
      }
      data={query =>
        new Promise((resolve, reject) => {
          const queryParams = {};
          query.filters.forEach(filter => {
            const field = filter.column.field;
            if (field === 'receivedAt') {
              const momentObj = moment(filter.value);
              const date = momentObj.date();
              const month = momentObj.month();
              const year = momentObj.year();
              const startTimeUTC = moment(Date.UTC(year, month, date))

              queryParams['startDate'] = startTimeUTC.valueOf();
              queryParams['endDate'] = startTimeUTC.add(1, 'day').valueOf();

            } else {
              const value = filter.value;
              if (value) {
                queryParams[field] = value;
              }
            }
          });

          if (query.orderBy) {
            queryParams['orderBy'] = query.orderBy.field;
            queryParams['orderDirection'] = query.orderDirection ? query.orderDirection : 'asc'
          }
          let url = 'api/payments'
          axios.get(url, {
            params: {
              rowsperpage: query.pageSize,
              page: query.page,
              ...queryParams
            }
          })
            .then(result => {
              resolve({
                data: result.data.data,
                page: result.data.page - 1,
                totalCount: result.data.totalCount,
              })
            })
        })
      }
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
        selection: false,
        debounceInterval: 500,
        filtering: true,
        search: false,
        thirdSortClick: false
      }}
      actions={[
        {
          icon: tableIcons.RefreshIcon,
          tooltip: 'Refresh Payments',
          isFreeAction: true,
          onClick: () => {
            tableRef.current && tableRef.current.onQueryChange()
          },
        }
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              // if (oldData) {
              //   setState((prevState) => {
              //     const data = [...prevState.data];
              //     data[data.indexOf(oldData)] = newData;
              //     return { ...prevState, data };
              //   });
              // }
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            //   setState((prevState) => {
            //     const data = [...prevState.data];
            //     data.splice(data.indexOf(oldData), 1);
            //     return { ...prevState, data };
            //   });
            }, 600);
          }),
      }}
    />
  );
};

PaymentsTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default PaymentsTable;
