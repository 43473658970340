import { FETCH_APP_USERS, FETCH_APP_USERS_SUCCESS, FETCH_APP_USERS_FAILURE } from '../constants';
import { LOGIN_FAILURE, LOG_OUT } from '../../SignIn/constants';

const initialAppUserState = { dataLoaded: false, dataLoadFailure: false, users: [] };

const UserReducer = (state = initialAppUserState, action) => {
  switch (action.type) {
    case FETCH_APP_USERS:
      return {
        ...state,
        dataLoaded: false,
      };
    case FETCH_APP_USERS_SUCCESS:
      return {
        ...state,
        dataLoaded: true,
        users: action.payload.data,
      };
    case FETCH_APP_USERS_FAILURE:
      return {
        dataLoadFailure: true,
      };
    case LOG_OUT:
    case LOGIN_FAILURE:
      return {};
    default:
      return state;
  }
};

export default UserReducer;
