import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, FormControlLabel, Grid, TableCell, TableRow, Typography, Table, TableBody, TableHead, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { Clear } from '@material-ui/icons';

const schema = {
  billAmount: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0
    }
  }
};

const ONLY_NUMBERS_REGEX = /^[0-9]*$/;

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const BillAmountModal = props => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    setFormState({
      isValid: false,
      touched: {},
      values: {},
      errors: {}
    })
  };

  const [formState, setFormState] = useState({
    isValid: false,
    touched: {
      billAmount: true
    },
    values: {
      billAmount: props.estimatedAmount,
      noDP: true,
      distributorPrice: 0,
      servicePartItems: [{ itemName: '', itemPrice: '' }]
    },
    errors: {}
  });
  useEffect(() => {
    const { servicePartItems, noDP } = formState.values;

    let isValid = true;
    let errorFields = [];
    const errors = validate(formState.values, schema);
    if (errors) {
      errorFields.push('Bill Amount')
      isValid = false
    }
    if (!noDP) {

      servicePartItems.forEach((item, index) => {
        if (!item.itemName.length) {
          errorFields.push(`Item Name ${index + 1}`)
          isValid = false;
        }
        if (!Number(item.itemPrice) > 0) {
          errorFields.push(`Item Price ${index + 1}`)
          isValid = false;
        }
      })
    }
    const errorMessage = errorFields.length ? `Please enter valid values for ${errorFields.join(', ')}` : '';
    setFormState(formState => ({
      ...formState,
      isValid,
      errors: errors || {},
      errorMessage
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isUpdatingBillAmount, setUpdateBillAmount] = React.useState(false);


  const handleUpdateBillAmount = async () => {
    const { billAmount, noDP, servicePartItems } = formState.values;

    const formData = {
      billAmount,
      currentStatus: 3,
      ...(noDP && { distributorPrice: 0 }),
      ...(!noDP && {
        distributorPrice: caculateSum(),
        servicePartItems
      })
    }
    try {
      setUpdateBillAmount(true);
      await axios.put(`/api/updateBill/${props.billId}`, formData);
      dispatch(showSnack('UPDATE_BILL_DETAILS', {
        button: { label: 'OKAY' },
        label: 'Updated Successfully',
        timeout: 2000
      }));
      props.handleUpdate();
    } catch (e) {
      setUpdateBillAmount(false);
      console.log('error', e);
      dispatch(showSnack('UPDATE_BILL_AMOUNT_ERROR', {
        label: 'Something went wrong..!',
        timeout: 3000
      }));

    }
  }

  const updateItem = (key, value, index) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        servicePartItems: formState.values.servicePartItems.map((item, itemIndex) => {
          if (index === itemIndex) {
            if ((key === 'itemPrice' && !ONLY_NUMBERS_REGEX.test(value))) {
              return item;
            }
            return { ...item, [key]: value }
          }
          return item;
        })
      }
    }))
  }

  const addItem = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        servicePartItems: [...formState.values.servicePartItems, { itemName: '', itemPrice: '' }]
      }
    }));
  }

  const caculateSum = () => {
    return formState.values.servicePartItems.map(i => Number(i.itemPrice)).reduce((acc, cur) => acc + cur)
  }

  const removeItem = index => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        servicePartItems: formState.values.servicePartItems.filter((item, i) => i !== index)
      }
    }))
  }

  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <TableCell>Item Name</TableCell>
          <TableCell>Price</TableCell>
        </TableHead>
        <TableBody>
          {
            formState.values.servicePartItems.map((item, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      label={`Item Name ${index + 1}`}
                      margin="dense"
                      onChange={event => updateItem('itemName', event.target.value, index)}
                      placeholder={`Item Name ${index + 1}`}
                      required
                      value={item.itemName}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      error={hasError('billAmount')}
                      fullWidth
                      label={`Item Price ${index + 1}`}
                      margin="dense"
                      name="billAmount"
                      onChange={event => updateItem('itemPrice', event.target.value, index)}
                      placeholder={`Item Price ${index + 1}`}
                      required
                      value={item.itemPrice}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <Clear onClick={() => removeItem(index)} />
                  </TableCell>
                </TableRow>
              )
            })
          }
          <TableRow>
            <TableCell><Typography variant="h6">Sum</Typography></TableCell>
            <TableCell>{caculateSum()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Button variant="contained" color="primary" size="small" onClick={addItem}>Add Item</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        maxWidth={'xs'}
        onClose={handleClose}
        open={true}
      >
        <DialogTitle className={classes.modalHeader}>Bill Amount</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('billAmount')}
                fullWidth
                label="Bill Amount"
                margin="dense"
                name="billAmount"
                onChange={handleChange}
                placeholder="Bill Amount"
                required
                value={formState.values.billAmount || ''}
                variant="standard"
              />
            </Grid>
            <div style={{ paddingTop: '10px' }}></div>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="h6" align="center">
                Distributer Price
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.noDP}
                    name="noDP"
                    onChange={handleChange}
                  />
                }
                label="No Distributor price for this bill"
              />
              {
                !formState.values.noDP &&
                renderTable(formState.values.dpValues)
              }
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title={!isUpdatingBillAmount ? formState.errorMessage : ''} placement="top">
            <span>
              <Button
                color="primary"
                disabled={!formState.isValid || isUpdatingBillAmount}
                onClick={handleUpdateBillAmount}
                variant="contained"
              >
                {!isUpdatingBillAmount ? <>Update Bill Amount</> : <>Updating</>}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BillAmountModal.propTypes = {
  className: PropTypes.string
};

export default BillAmountModal;
