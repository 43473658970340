import React, { useEffect, useRef, useState } from 'react';
import Routes from './Routes';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import axios from 'axios';
import { setUpAxiosConfig } from './helpers/network-utils';
import { loginSuccess, loginFailure } from './views/SignIn/actions';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';

const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_SESSION_EXPIRATION_IN_SECONDS: SESSION_EXPIRATION_IN_SECONDS
} = process.env;

const SessionHandler = props => {
  const [showSessionWarningModal, setShowSessionWarningModal] = useState(false);
  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(
    state => state.UserReducer.isValidatedFromState
  );
  const warningTimeout = useRef(null);
  const logoutTimeout = useRef(null);
  const logoutRemainingTimeout = useRef(null);
  const dispatch = useDispatch();
  const [remainingSeconds, setRemainingSeconds] = useState(60);

  const startTimeouts = () => {
    const SESSION_EXPIRATION_TIMEOUT = SESSION_EXPIRATION_IN_SECONDS * 1000;
    const SESSION_WARNING_TIMEOUT = SESSION_EXPIRATION_TIMEOUT - 60000;

    warningTimeout.current = setTimeout(() => {
      setShowSessionWarningModal(true);

      logoutRemainingTimeout.current = setInterval(() => {
        setRemainingSeconds(seconds => seconds - 1);
      }, 1000);
    }, SESSION_WARNING_TIMEOUT);

    logoutTimeout.current = setTimeout(() => {
      logout();
    }, SESSION_EXPIRATION_TIMEOUT);
  };

  useEffect(() => {
    if (!isValidatedFromState && isLoggedIn) {
      startTimeouts();
    }
    if (!isLoggedIn) {
      resetValues();
    }
  }, [isLoggedIn, isValidatedFromState]);

  const extendSession = async () => {
    const url = '/api/auth/extendSession';

    resetValues();

    try {
      const request = await axios.get(url);
      const { data } = request;

      startTimeouts();

      setUpAxiosConfig(API_URL, data.accessToken);
      dispatch(loginSuccess(data));
      dispatch(
        showSnack('myUniqueId', {
          button: { label: 'GOT IT' },
          label: `Session Extended Successfully !`,
          timeout: 3000
        })
      );
    } catch (e) {
      dispatch(
        showSnack('myUniqueId', {
          button: { label: 'GOT IT' },
          label: 'Unable to extend session. Please Login Back !',
          timeout: 3000
        })
      );
      localStorage.clear();
      dispatch(loginFailure());
    }
  };

  const resetValues = () => {
    clearInterval(logoutRemainingTimeout.current);
    clearTimeout(warningTimeout.current);
    clearTimeout(logoutTimeout.current);
    setRemainingSeconds(60);
    setShowSessionWarningModal(false);
  };

  const logout = () => {
    console.log('Logging Out');
    resetValues();

    dispatch({ type: 'LOG_OUT' });
    localStorage.clear();
  };

  return (
    <>
      {showSessionWarningModal && (
        <Dialog onClose={() => {}} open={true}>
          <DialogTitle>Session Alert</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Session will be timed out in {remainingSeconds} seconds.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={logout} color="secondary">
              Logout
            </Button>
            <Button onClick={extendSession} color="primary">
              Extend Session
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Routes />
    </>
  );
};

export default SessionHandler;
