import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import EdiText from 'react-editext';

const useStyles = makeStyles(() => ({
  cardBody: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  },
  editButton: {
    padding: 3,
    background: 'none',
    border: '1px solid'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const CustomerDetails = props => {
  const { isLoading, customerDetails } = props;
  console.log('customerDetails', customerDetails);
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Customer Info" />
      <CardContent className={classes.cardBody}>
        {isLoading ? (
          <div className={classes.loaderStyle}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '80%' }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell variant="head">First Name</TableCell>
                <TableCell>{customerDetails.firstName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Last Name</TableCell>
                <TableCell>{customerDetails.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Contact</TableCell>
                <TableCell>{customerDetails.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Email</TableCell>
                <TableCell>{customerDetails.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default CustomerDetails;
