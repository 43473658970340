import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  }
}));

const BillReturnModal = props => {
  const dispatch = useDispatch();
  const { className, customerDetails, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    setFormState({
      isValid: false,
      touched: {},
      values: {
        receivedBy: 'customer'
      },
      errors: {}
    })
  };

  const [formState, setFormState] = useState({
    pendingAmount: 0,
    isValid: false,
    touched: {},
    values: {
      receivedBy: 'customer'
    },
    errors: {}
  });

  useEffect(() => {
    const { receivedBy, receievedByPerson } = formState.values;
    let isValid = true;

    if (receivedBy === 'others' && !receievedByPerson) {
      isValid = false;
    }

    setFormState(formState => ({
      ...formState,
      isValid
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isUpdatingBillReturn, setUpdateBillReturn] = React.useState(false);


  const handleUpdateBillReturn = async () => {
    const { receivedBy: receivedByType, receievedByPerson } = formState.values;
    const receivedBy = receivedByType === 'customer' ? `${customerDetails.firstName} ${customerDetails.lastName}` : receievedByPerson
    const formData = {
      currentStatus: 6,
      receivedBy
    }
    try {
      await axios.put(`/api/updateBill/${props.billId}`, formData);
      dispatch(showSnack('UPDATE_BILL_DETAILS', {
        button: { label: 'OKAY' },
        label: 'Updated Successfully',
        timeout: 2000
      }));
      props.handleUpdate('DELIVERY');
    } catch (e) {
      setUpdateBillReturn(false);
      console.log('error', e);
      dispatch(showSnack('UPDATE_BILL_AMOUNT_ERROR', {
        label: 'Something went wrong..!',
        timeout: 3000
      }));

    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Dialog
        maxWidth={'sm'}
        onClose={handleClose}
        open
      >
        <DialogTitle className={classes.modalHeader}>Bill Return</DialogTitle>
        <DialogContent>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>Received By</Typography>
              <RadioGroup row value={formState.values.receivedBy} onChange={handleChange}>
                <FormControlLabel
                  name="receivedBy"
                  value='customer'
                  control={
                    <Radio color="primary" />
                  }
                  label={`${customerDetails.firstName} ${customerDetails.lastName} (Customer)`}

                />
                <FormControlLabel
                  name="receivedBy"
                  value='others'
                  control={
                    <Radio color="primary" />
                  }
                  label="Others"

                />
              </RadioGroup>
            </Grid>
            {formState.values.receivedBy === 'others' &&
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={hasError('receievedByPerson')}
                  fullWidth
                  label="Received By"
                  margin="dense"
                  name="receievedByPerson"
                  onChange={handleChange}
                  placeholder="Received By"
                  required
                  value={formState.values.receievedByPerson || ''}
                  variant="standard"
                />
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={!formState.isValid || isUpdatingBillReturn}
            onClick={handleUpdateBillReturn}
            variant="contained"
          >
            {!isUpdatingBillReturn ? <>Update Return Details</> : <>Updating</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

BillReturnModal.propTypes = {
  className: PropTypes.string
};

export default BillReturnModal;
