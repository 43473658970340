export const statusMappings = {
  1: 'Pending',
  2: 'In Progress',
  3: 'Completed',
  4: 'Delivered',
  5: 'Rejected',
  6: 'Returned'
};

export const billVarsMappings = {
  currentStatus: 'Status',
  caseSummary: 'Case Summary',
  engineerSummary: 'Engineer Summary',
  estimatedAmount: 'Estimated Amount',
  billAmount: 'Bill Amount',
  receivedBy: 'Received By',
  defects: 'Defects',
  deviceName: 'Device Name',
  deviceModel: 'Device Model',
  deviceModelType: 'Device Model Type',
  sim: 'SIM',
  simTray: 'SIM Tray',
  battery: 'Battery',
  otherItems: 'Other Items',
  otherItemscomputer: 'Contains',
  pouch: 'Pouch',
  backdoor: 'Back Door',
  imei: 'IMEI ',
  servicePartItems: 'Service Parts',
  distributorPrice: 'Distributor Price',
  rejectionComments: 'Rejection Comments',
  reference: 'Reference'
}