import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useSelector } from 'react-redux';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const userRole = useSelector(state => state.UserReducer.user.roles[0]);

  const pages = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      Icon: DashboardIcon,
      position: 0
    },
    {
      name: 'Customers',
      link: '/customers',
      Icon: PeopleIcon,
      position: 0
    },
    {
      name: 'Services',
      Icon: DashboardIcon,
      position: 2,
      link: '/',
      items: [
        {
          name: 'Bills',
          link: '/bills'
        },
        {
          name: 'Payments',
          link: '/payments'
        },
        ...(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(userRole)
          ? [
              {
                name: 'Reports',
                link: '/reports'
              }
            ]
          : [])
      ]
    },
    {
      name: 'Account',
      link: '/account',
      Icon: AccountBoxIcon,
      position: 2
    }
    // {
    //   name: 'Settings',
    //   link: '/settings',
    //   Icon: SettingsIcon>
    // }
  ];

  if (['ROLE_SUPER_ADMIN'].includes(userRole)) {
    pages.push({
      name: 'Users',
      link: '/users',
      Icon: PeopleIcon,
      position: 1
    });
  }
  const sortedPages = pages.sort((a, b) => a.position - b.position);
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={sortedPages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
