import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { isSuperAdmin } from 'helpers/common';

import { Card, CardContent, Avatar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import getInitials from 'helpers/getInitials';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const userDetails = useSelector(state => state.UserReducer.user);

  const { firstName, lastName, workLocation } = userDetails;

  console.log(userDetails);

  const isSuperAdminRole = isSuperAdmin(userDetails.roles);

  const user = {
    name: `${firstName} ${lastName}`,
    workLocation,
    avatar: '/images/avatars/user.png'
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {user.name}
            </Typography>
            {isSuperAdminRole && (
              <Typography
                className={classes.locationText}
                color="textSecondary"
                variant="body1">
                Super Admin
              </Typography>
            )}
            {!isSuperAdminRole && (
              <Typography
                className={classes.locationText}
                color="textSecondary"
                variant="body1">
                {user.workLocation}
              </Typography>
            )}
          </div>
          <Avatar className={classes.avatar} src={user.avatar}>
            {getInitials(firstName, lastName)}
          </Avatar>
        </div>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Upload picture
        </Button>
        <Button variant="text">Remove picture</Button>
      </CardActions> */}
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
