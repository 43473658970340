import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from './tableIcons';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { history } from '../../../../helpers/history';

const CustomersTable = props => {
  const { customers = [] } = props;

  const [state, setState] = React.useState({
    columns: [
      {
        title: 'Name',
        field: 'name',
        editComponent: props => {
          return (
            <>
              <TextField
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={e =>
                  props.onRowDataChange({
                    ...props.rowData,
                    firstName: e.target.value
                  })
                }
                required
                value={props.rowData.firstName}
                variant="standard"
              />
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={e =>
                  props.onRowDataChange({
                    ...props.rowData,
                    lastName: e.target.value
                  })
                }
                required
                value={props.rowData.lastName}
                variant="standard"
              />
            </>
          );
        }
      },
      { title: 'Email', field: 'email', editable: 'never' },
      { title: 'Phone', field: 'phone' }
    ],
    data: customers.map(user => {
      const { firstName, lastName, email, phone, alternatePhone, id } = user;

      let updatedData = {
        id,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        phone,
        alternatePhone
      };
      return updatedData;
    })
  });

  return (
    <MaterialTable
      title="All Customers"
      columns={state.columns}
      data={state.data}
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
        selection: true
      }}
      onRowClick={(event, rowData) => {
        console.log(rowData);
        history.push(`/customers/${rowData.id}`);
      }}
    />
  );
};

CustomersTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default CustomersTable;
