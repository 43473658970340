import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { getAppCustomers } from '../../actions';

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    email: true
  },
  phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10
    }
  },
  alternatePhone: {
    length: {
      maximum: 10
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const UsersToolbar = props => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormState({
      isValid: false,
      touched: {},
      values: {},
      errors: {}
    });
    setCreatingUser(false);
    setOpen(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    values: {},
    errors: {}
  });
  useEffect(() => {
    let errors = validate(formState.values, schema);
    if (errors && errors.email && !formState.values.email) {
      delete errors.email;
    }
    setFormState(formState => ({
      ...formState,
      isValid: errors && Object.keys(errors).length > 0 ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    resetFormPostErrors();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const resetFormPostErrors = () => {
    setEmailError(false);
    setPhoneError(false);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [iscreatingUser, setCreatingUser] = React.useState(false);

  const [isPhoneError, setPhoneError] = React.useState(false);

  const [isEmailError, setEmailError] = React.useState(false);

  const handleCreateUser = async () => {
    const { firstName, lastName, email, phone } = formState.values;

    const formData = {
      firstName,
      lastName,
      email,
      phone
    };
    try {
      setCreatingUser(true);
      const url = '/api/createCustomer';
      await axios.post(url, formData);
      dispatch(getAppCustomers());
      dispatch(
        showSnack('CREATE_CUSTOMER_SUCCESS', {
          label: `Successfully created Customer ${firstName} ${lastName}..!`,
          timeout: 3000
        })
      );
      handleClose();
    } catch (e) {
      setCreatingUser(false);
      console.log('error', e);
      if (
        e.response &&
        e.response.data &&
        e.response.data.errorCode === 'PHONE_EXISTS'
      ) {
        setPhoneError(true);
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.errorCode === 'EMAIL_EXISTS'
      ) {
        setEmailError(true);
      } else {
        dispatch(
          showSnack('CREATE_CUSTOMER_ERROR', {
            label: 'Something went wrong..!',
            timeout: 3000
          })
        );
      }
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button color="primary" onClick={handleClickOpen} variant="contained">
          Add Customer
        </Button>
      </div>
      <Dialog maxWidth={'xs'} onClose={handleClose} open={open}>
        <DialogTitle className={classes.modalHeader} id="form-dialog-title">
          Add New Customer
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('firstName')}
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                placeholder="First Name"
                required
                value={formState.values.firstName || ''}
                variant="standard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('lastName')}
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={formState.values.lastName || ''}
                variant="standard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('email') || isEmailError}
                fullWidth
                helperText={isEmailError ? 'Email already Exists' : ''}
                label="Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                value={formState.values.email || ''}
                variant="standard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('phone') || isPhoneError}
                fullWidth
                helperText={
                  isPhoneError
                    ? 'Phone number already Exists'
                    : 'Enter phone number without code and spaces'
                }
                label="Phone"
                margin="dense"
                name="phone"
                onChange={handleChange}
                required
                value={formState.values.phone || ''}
                variant="standard"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError('alternatePhone')}
                fullWidth
                helperText="Enter phone number without code and spaces"
                label="Alternate Phone"
                margin="dense"
                name="alternatePhone"
                onChange={handleChange}
                value={formState.values.alternatePhone || ''}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!formState.isValid || iscreatingUser}
            onClick={handleCreateUser}
            variant="contained">
            {!iscreatingUser ? <>create New Customer</> : <>Adding Customer</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
