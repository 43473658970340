import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from './tableIcons';
import PropTypes from 'prop-types';
import { getInitials } from 'helpers';
import { Switch, Avatar, TextField } from '@material-ui/core';
import axios from 'axios';

const UsersTable = props => {

  const { users } = props;

  const getAccessLevel = roles => {
    if(roles.includes('ROLE_SUPER_ADMIN')) {
      return 3;
    } else if(roles.includes('ROLE_ADMIN')) {
      return 2;
    } else {
      return 1;
    }
  }

  const toggleUser = e => {
    console.log(e.target.checked);
    
    axios.put('/api/toggleUser')
  }

  const [state, setState] = React.useState({
    columns: [
      { title: 'Avatar', field: 'avatar', editable: 'never', render: rowData => {        
        return <Avatar
          src={rowData.avatarUrl}
        >
          {getInitials(rowData.firstName, rowData.lastName)}
        </Avatar>
       } },
      { title: 'Name', field: 'name', editComponent: props => {
        return (
          <>
            <TextField
              fullWidth
              label="First name"
              margin="dense"
              name="firstName"
              onChange={e => props.onRowDataChange({
                ...props.rowData,
                firstName: e.target.value
               })
              }
              required
              value={props.rowData.firstName}
              variant="standard"
            />
            <TextField
              fullWidth
              label="Last name"
              margin="dense"
              name="lastName"
              onChange={e => props.onRowDataChange({
                ...props.rowData,
                lastName: e.target.value
               })
              }
              required
              value={props.rowData.lastName}
              variant="standard"
            />
          </>
        )
       } },
      { title: 'Username', field: 'username', editable: 'never' },
      { title: 'Email', field: 'email', editable: 'never' },
      { title: 'Work Location', field: 'workLocation' },
      { title: 'Phone', field: 'phone' },
      { title: 'Roles', field: 'roles', lookup: { 1: 'User', 2: 'Admin', 3: 'Super Admin' } },
      { title: 'Active', field: 'isDisabled', editable: 'never',
        render: user => {
          return <Switch
            checked={!user.isDisabled}
            onChange={toggleUser}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
      },
      
    ],
    data: users.map(user => {
      let updatedData = {
        name: `${user.firstName} ${user.lastName}`,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        workLocation: user.workLocation,
        phone: user.phone ? user.phone : '-',
        roles:getAccessLevel(user.roles),
        isDisabled: user.isDisabled
      };
      return updatedData;
    }) 
   
  });

  return (
    <MaterialTable
      title="All Users"
      columns={state.columns}
      data={state.data}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                setState((prevState) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
        selection: true
      }}
    />
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
