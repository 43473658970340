import axios from 'axios';
import { FETCH_APP_USERS, FETCH_APP_USERS_SUCCESS, FETCH_APP_USERS_FAILURE } from '../constants';
import { dispatch } from '../../../helpers/store-helper';

export const fetchAppUsersStart = () => ({ type: FETCH_APP_USERS });

export const fetchAppUsersSucess = data => ({
  payload: {
    data
  },
  type: FETCH_APP_USERS_SUCCESS
});

export const fetchAppUsersFailure = () => ({ type: FETCH_APP_USERS_FAILURE });

export const getAppUsers = () => async () => {
  const url = '/api/users';
  try {
    dispatch(fetchAppUsersStart());
    const request = await axios.get(url);
    const { data } = request;
    dispatch(fetchAppUsersSucess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAppUsersFailure());
  }
}
