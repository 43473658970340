import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import {
  Budget,
  TotalBills,
  TasksProgress,
  TotalProfit,
  LatestSales,
  BillsByDevice,
  LatestProducts,
  LatestOrders
} from './components';

import { BillsList } from '../index';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(
    state => state.UserReducer.isValidatedFromState
  );
  const isLoading = isValidatedFromState || !isLoggedIn;

  const start = moment()
    .utc()
    .day(1)
    .startOf('day')
    .format();
  const end = moment()
    .utc()
    .day(7)
    .endOf('day')
    .format();
  const startOfWeek = moment(start).format('DD/MM/YYYY');
  const endOfWeek = moment(end).format('DD/MM/YYYY');
  console.log('final', start, end, startOfWeek, endOfWeek);

  const [dashboardData, setDashboardData] = useState({
    isLoading: true,
    thisWeekBillsCount: {},
    totalBillsCount: 0,
    billsByDevice: {}
  });

  useEffect(() => {
    if (!isLoading) {
      axios
        .get(
          `/api/getMetaForDashboard?weekStartTime=${start}&weekEndTime=${end}`
        )
        .then(res => {
          console.log('response', res);
          setDashboardData(dashboardState => ({
            ...dashboardState,
            isLoading: false,
            totalBillsCount: res.data.totalBillsCount,
            thisWeekBillsCount: res.data.thisWeekBillsCount,
            billsByDevice: res.data.billsByDevice
          }));
        })
        .catch(error => {
          console.log('Error', error);
        });
    }
  }, [isLoading]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className={classes.loaderStyle}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container spacing={4}>
            {!dashboardData.isLoading && (
              <>
                <Grid item lg={6} sm={6} xl={6} xs={12}>
                  <TotalBills
                    type="allTime"
                    totalBillsCount={dashboardData.totalBillsCount}
                  />
                </Grid>
                <Grid item lg={6} sm={6} xl={6} xs={12}>
                  <TotalBills
                    type="thisweek"
                    startOfWeek={startOfWeek}
                    endOfWeek={endOfWeek}
                    thisWeekBillsCount={dashboardData.thisWeekBillsCount}
                  />
                </Grid>
                {/* <Grid item lg={8} md={12} xl={9} xs={12}>
                  <LatestSales />
                </Grid> */}
                <Grid item lg={4} md={6} xl={3} xs={12}>
                  <BillsByDevice
                    data={dashboardData.billsByDevice}
                    totalBillsCount={dashboardData.totalBillsCount}
                  />
                </Grid>
              </>
            )}
            {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget />
            </Grid>

            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TasksProgress />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit />
            </Grid> */}
            <Grid item lg={12} md={12} xl={9} xs={12}>
              <BillsList isDashboard />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Dashboard;
