import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import ReportsType from './components/ReportType';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center'
  }
}));

const Reports = () => {
  const classes = useStyles();
  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(state => state.UserReducer.isValidatedFromState);
  const isLoading = isValidatedFromState || !isLoggedIn;

  const onExportClick = () => {
    console.log('hre');
  }

  return (
    <div className={classes.root}>
      {
        isLoading ?
          <div className={classes.loaderStyle}>
            <CircularProgress />
          </div> :
          <div className={classes.content}>
            <ReportsType onExportClick={onExportClick}/>
          </div>
      }
    </div>
  );
};

export default Reports;
