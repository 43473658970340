import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Select, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { getAppUsers } from '../../actions';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  workLocation: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    email: true
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  modalHeader: {
    textAlign: 'center',
    fontSize: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const UsersToolbar = props => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCreatingUser(false);
    setOpen(false);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      role: 'user'
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    resetFormPostErrors();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const resetFormPostErrors = () => {
    setEmailError(false);
    setUsernameError(false);
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [iscreatingUser, setCreatingUser] = React.useState(false);

  const [isUsernameError, setUsernameError] = React.useState(false);

  const [isEmailError, setEmailError] = React.useState(false);

  const handleCreateUser = async () => {
    const { username, firstName, lastName, email, role, password, workLocation } = formState.values;
    console.log(username, firstName, lastName, email, role, password, workLocation);
    const formData = {
      username, firstName, lastName, email, password, workLocation, roles: [role]
    }
    try {
      setCreatingUser(true);
      const url = '/api/createUser';
      await axios.post(url, formData);
      dispatch(getAppUsers());
      dispatch(showSnack('CREATE_USER_SUCCESS', {
        label: `Successfully created user ${username}..!`,
        timeout: 3000
      }));
      setCreatingUser(false);
      setOpen(false);
    } catch (e) {
      setCreatingUser(false);
      console.log('error', e);
      if (e.response && e.response.data && e.response.data.errorCode === 'USERNAME_EXISTS') {
        setUsernameError(true);
      } else if (e.response && e.response.data && e.response.data.errorCode === 'EMAIL_EXISTS') {
        setEmailError(true);
      }
      else {
        dispatch(showSnack('CREATE_USER_ERROR', {
          label: 'Something went wrong..!',
          timeout: 3000
        }));
      }
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          onClick={handleClickOpen}
          variant="contained"
        >
          Add user
        </Button>
      </div>
      <Dialog
        maxWidth={'xs'}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle className={classes.modalHeader} id="form-dialog-title">Add New User</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('username') || isUsernameError}
                fullWidth
                helperText={isUsernameError ? 'Username already Exists' : 'This will be used for login'}
                label="Username"
                margin="dense"
                name="username"
                onChange={handleChange}
                required
                value={formState.values.username || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('email') || isEmailError}
                fullWidth
                helperText={isEmailError ? 'Email already Exists' : ''}
                label="Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                value={formState.values.email || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('firstName')}
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                placeholder="First Name"
                required
                value={formState.values.firstName || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('lastName')}
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={formState.values.lastName || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('password')}
                fullWidth
                helperText="Minimum 8 Characters"
                label="Password"
                margin="dense"
                name="password"
                onChange={handleChange}
                required
                type="password"
                value={formState.values.password || ''}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Select
                className={classes.selectEmpty}
                fullWidth
                labelId="role-level"
                margin="dense"
                name="role"
                onChange={handleChange}
                placeholder="Access Level"
                required
                value={formState.values.role}
                variant="standard"
              >
                <MenuItem
                  disabled
                  value=""
                >
                  Access Level
                </MenuItem>
                <MenuItem value={'user'}>User</MenuItem>
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'super_admin'}>Super Admin</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={hasError('workLocation')}
                fullWidth
                label="Location"
                margin="dense"
                name="workLocation"
                onChange={handleChange}
                required
                value={formState.values.workLocation || ''}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!formState.isValid || iscreatingUser}
            onClick={handleCreateUser}
            variant="contained"
          >
            {!iscreatingUser ? <>Add User</> : <>Creating User</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
