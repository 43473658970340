import { FETCH_APP_CUSTOMERS, FETCH_APP_CUSTOMERS_SUCCESS, FETCH_APP_CUSTOMERS_FAILURE } from '../constants';
import { LOGIN_FAILURE, LOG_OUT } from '../../SignIn/constants';

const initialAppCustomerState = { dataLoaded: false, dataLoadFailure: false, customers: [] };

const CustomerReducer = (state = initialAppCustomerState, action) => {
  switch (action.type) {
    case FETCH_APP_CUSTOMERS:
      return {
        ...state,
        dataLoaded: false
      }
    case FETCH_APP_CUSTOMERS_SUCCESS:
      return {
        ...state,
        dataLoaded: true,
        customers: action.payload.data
      }
    case FETCH_APP_CUSTOMERS_FAILURE:
      return {
        dataLoadFailure: true
      };
    case LOG_OUT:
    case LOGIN_FAILURE:
      return {};
    default:
      return state;
  }
};

export default CustomerReducer;
