import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAppCustomers } from './actions';
import { CustomersToolbar, CustomersTable } from './components';
// import mockData from './data';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center'
  }
}));

const CustomerList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoading = useSelector(state => state.CustomerReducer.dataLoaded === false);
  const isLoadingFailure = useSelector(state => state.CustomerReducer.dataLoadFailure === true);
  const customers = useSelector(state => state.CustomerReducer.customers);
  const isLoggedIn = useSelector(state => state.UserReducer.isLoggedIn);
  const isValidatedFromState = useSelector(state => state.UserReducer.isValidatedFromState);
  console.log(isLoggedIn);
  useEffect(() => {
    if (!isValidatedFromState && isLoggedIn) {
      dispatch(getAppCustomers());
    }
  }, [dispatch, isLoggedIn, isValidatedFromState])

  if (isLoadingFailure) {
    return <>Load Failure</>
  }
  return (
    <div className={classes.root}>
      <CustomersToolbar />
      {
        isLoading ?
          <div className={classes.loaderStyle}>
            <CircularProgress />
          </div> :
          <div className={classes.content}>
            <CustomersTable customers={customers} />
          </div>
      }
    </div>
  );
};

export default CustomerList;
