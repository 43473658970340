/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, colors } from '@material-ui/core';
import AppMenuItem from '../AppMenuItem';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {pages.map((page, index) => (
        <AppMenuItem {...page} key={index} />
      ))}
    </List>
  );

  // return (
  //     <List
  //         {...rest}
  //         className={clsx(classes.root, className)}
  //     >
  //         {pages.map(page => {
  //             if (page.children) {
  //                 return (
  //                     <>
  //                         <ListItem
  //                             button
  //                             onClick={() => this.handleClick(subOption.name)}>
  //                             <ListItemText
  //                                 inset
  //                                 primary={page.title} />
  //                             {state[subOption.name] ?
  //                                 <ExpandLess /> :
  //                                 <ExpandMore />
  //                             }
  //                         </ListItem>
  //                         <Collapse
  //                             in={state[subOption.name]}
  //                             timeout="auto"
  //                             unmountOnExit
  //                         >
  //                             {this.handler(subOption.children)}
  //                         </Collapse>
  //                     </>
  //                 );
  //             }
  //             return (
  //                 <ListItem
  //                     className={classes.item}
  //                     disableGutters
  //                     key={page.title}
  //                     button
  //                 >
  //                     <Button
  //                         activeClassName={classes.active}
  //                         className={classes.button}
  //                         component={CustomRouterLink}
  //                         to={page.href}
  //                     >
  //                         <div className={classes.icon}>{page.icon}</div>
  //                         {page.title}
  //                     </Button>
  //                 </ListItem>
  //             )
  //         })}
  //     </List>
  // );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
