import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UsersToolbar, UsersTable } from './components';
// import mockData from './data';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  loaderStyle: {
    display: 'flex',
    paddingTop:'30px',
    justifyContent: 'center'
  }
}));

const UserList = () => {
  const classes = useStyles();

  const isLoading = useSelector(state => state.AppUsersReducer.dataLoaded === false);
  const isLoadingFailure = useSelector(state => state.AppUsersReducer.dataLoadFailure === true);
  const users = useSelector(state => state.AppUsersReducer.users);


  // const [users] = useState(mockData);

  if (isLoadingFailure) {
    return <>Load Failure</>
  }
  return (
    <div className={classes.root}>
      <UsersToolbar />
      {
        isLoading ? 
          <div className={classes.loaderStyle}>
            <CircularProgress />
          </div>:
          <div className={classes.content}>
            <UsersTable users={users} />
          </div>
      }
    </div>
  );
};

export default UserList;
