import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from './tableIcons';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import { history } from '../../../../helpers/history';
import { statusMappings } from '../../../BillEdit/mappings';
import { Link } from '@material-ui/core';

const CustomersTable = props => {
  const tableRef = props.tableRef;
  return (
    <MaterialTable
      tableRef={tableRef}
      title="All Bills"
      columns={[
        {
          title: 'Bill Ref',
          field: 'billId',
          render: rowData => {
            return <>{`FFT${String(rowData.billId).padStart(4, '0')}`}</>;
          }
        },
        {
          title: 'Customer Name',
          field: 'customerName',
          render: rowData => {
            return (
              <Link
                component="a"
                onClick={e => {
                  e.stopPropagation();
                  history.push(`/customers/${rowData.customer._id}`);
                }}>
                {rowData.customer.firstName} {rowData.customer.lastName}
              </Link>
            );
          }
        },
        {
          title: 'Device Type',
          field: 'deviceType',
          render: rowData => {
            const device = rowData.deviceType;
            return (
              <>
                {device !== 'cctv'
                  ? `${device[0].toUpperCase()}${device.slice(1)}`
                  : device.toUpperCase()}
              </>
            );
          }
        },
        {
          title: 'Device Name',
          field: 'deviceName',
          render: rowData => {
            return <>{rowData.deviceName || '-'}</>;
          }
        },
        {
          title: 'Device Model',
          field: 'deviceModel',
          render: rowData => {
            return <>{rowData.deviceModel || '-'}</>;
          }
        },
        {
          title: 'Defects',
          field: 'defects',
          render: rowData => {
            return <>{rowData.defects || '-'}</>;
          }
        },
        {
          title: 'Bill Date',
          field: 'billDate',
          type: 'date',
          format: 'DD/MM/YYYY',
          render: rowData => {
            return <>{moment(rowData.billDate).format('DD/MM/YYYY')}</>;
          }
        },
        {
          title: 'Status',
          field: 'status',
          defaultFilter: ['1', '2', '3', '5'],
          render: rowData => {
            return <>{statusMappings[rowData.currentStatus]}</>;
          },
          lookup: statusMappings
        }
      ]}
      data={query =>
        new Promise((resolve, reject) => {
          const queryParams = {};
          query.filters.forEach(filter => {
            const field = filter.column.field;
            if (field === 'billDate') {
              const momentObj = moment(filter.value);
              const date = momentObj.date();
              const month = momentObj.month();
              const year = momentObj.year();
              const startTimeUTC = moment(Date.UTC(year, month, date));

              queryParams['startDate'] = startTimeUTC.valueOf();
              queryParams['endDate'] = startTimeUTC.add(1, 'day').valueOf();
            } else {
              const value =
                field === 'status' ? filter.value.join('-') : filter.value;
              if (value) {
                queryParams[field] = value;
              }
            }
          });

          if (query.orderBy) {
            queryParams['orderBy'] = query.orderBy.field;
            queryParams['orderDirection'] = query.orderDirection
              ? query.orderDirection
              : 'asc';
          }
          let url = 'api/bills';
          axios
            .get(url, {
              params: {
                rowsperpage: query.pageSize,
                page: query.page,
                ...queryParams
              }
            })
            .then(result => {
              resolve({
                data: result.data.data,
                page: result.data.page - 1,
                totalCount: result.data.totalCount
              });
            });
        })
      }
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
        selection: false,
        debounceInterval: 500,
        filtering: true,
        search: false,
        thirdSortClick: false
      }}
      actions={[
        {
          icon: tableIcons.RefreshIcon,
          tooltip: 'Refresh Bills',
          isFreeAction: true,
          onClick: () => {
            tableRef.current && tableRef.current.onQueryChange();
          }
        }
      ]}
      onRowClick={(event, rowData) => {
        history.push(`/bill/${rowData._id}`);
      }}
    />
  );
};

CustomersTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default CustomersTable;
