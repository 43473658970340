import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import FileSaver from 'file-saver';
import axios from 'axios';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import { statusMappings } from '../../../BillEdit/mappings';
import moment from 'moment';


const useStyles = makeStyles(() => ({
  cardBody: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  loaderStyle: {
    display: 'flex',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'center'
  },
  editButton: {
    padding: 3,
    background: 'none',
    border: '1px solid'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const schema = {
  fromDate: {
    datetime: {
      dateOnly: true,
      latest: moment.utc()
    },
    presence: { allowEmpty: false, message: 'is required' },
  },
  toDate: {
    datetime: {
      dateOnly: true,
      latest: moment.utc()
    },
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const dateRanges = {
  allTime: 'All Time',
  specificDates: 'Specific Dates'
}

const deviceTypes = {
  mobile: 'Mobile',
  cctv: 'CCTV',
  computer: 'Computer',
  others: 'Others'
}

const billColumns = {
  billId: 'Bill Number',
  billDate: 'Bill Date',
  createdAt: 'Bill Created Date',
  customerName: 'Customer Name',
  receivedBy: 'Received By',
  reference: 'Reference',
  deviceType: 'Device Type',
  deviceName: 'Device Name',
  deviceModel: 'Device Model',
  deviceModelType: 'Device Model Type',
  imei: 'IMEI',
  sim: 'Sim',
  simTray: 'Sim Tray',
  battery: 'Battery',
  pouch: 'Pouch',
  backdoor: 'Back Door',
  otherItems: 'Other Items',
  defects: 'Defects',
  caseSummary: 'Case Summary',
  engineerSummary: 'Engineer Summary',
  estimatedAmount: 'Estimated Amount',
  distributorPrice: 'Distributor Price',
  billAmount: 'Bill Amount',
  rejectionComments: 'Rejection Comments',
  currentStatus: 'Current Status',
  receivedBy: 'Received By'
}

const ReportsType = props => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: true,
    touched: {},
    values: {
      reportType: 'bill',
      dateRangeType: 'specificDates',
      statusToExport: Object.keys(statusMappings).reduce(function (o, val) { o[val] = true; return o; }, {}),
      deviceTypesToExport: Object.keys(deviceTypes).reduce(function (o, val) { o[val] = true; return o; }, {}),
      billColumnsToExport: Object.keys(billColumns).reduce(function (o, val) { o[val] = true; return o; }, {})
    },
    errors: {}
  });

  useEffect(() => {
    const { dateRangeType } = formState.values;
    const errors = validate(formState.values, schema);
    let isValid = true;
    if (dateRangeType === 'specificDates' && errors && (errors['fromDate'] || errors['toDate'])) {
      isValid = false;
    }
    setFormState(formState => ({
      ...formState,
      isValid,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event, name) => {
    let updatedStatuses = {};
    let updatedDeviceTypes = {};
    let updatedBillColumns = {};
    if (name === 'billStatus') {
      updatedStatuses = { ...formState.values.statusToExport };
      if (!event.target.checked) {
        delete updatedStatuses[event.target.name]
      } else {
        updatedStatuses[event.target.name] = true;
      }
    }
    if (name === 'deviceTypes') {
      updatedDeviceTypes = { ...formState.values.deviceTypesToExport };
      if (!event.target.checked) {
        delete updatedDeviceTypes[event.target.name]
      } else {
        updatedDeviceTypes[event.target.name] = true;
      }
      console.log(updatedDeviceTypes);
    }
    if (name === 'billColumns') {
      updatedBillColumns = { ...formState.values.billColumnsToExport };
      if (!event.target.checked) {
        delete updatedBillColumns[event.target.name]
      } else {
        updatedBillColumns[event.target.name] = true;
      }
    }
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ...(name === 'billStatus' && Object.keys(updatedStatuses).length > 0 && {
          statusToExport: updatedStatuses
        }),
        ...(name === 'deviceTypes' && Object.keys(updatedDeviceTypes).length > 0 && {
          deviceTypesToExport: updatedDeviceTypes
        }),
        ...(name === 'billColumns' && Object.keys(updatedBillColumns).length > 0 && {
          billColumnsToExport: updatedBillColumns
        }),
        ...(name !== 'billStatus' && name !== 'deviceTypes' && {
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }),

      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [isExporting, setIsExporting] = React.useState(false);

  const onExportClick = async () => {

    const { reportType, dateRangeType, fromDate, toDate, statusToExport, billColumnsToExport } = formState.values;

    const formData = {
      reportType,
      filterOptions: {
        dateRangeType,
        ...(dateRangeType === 'specificDates' && {
          dateRanges: {
            fromDate: new Date(fromDate).setHours(0, 0, 0, 0),
            toDate: new Date(toDate).setHours(23, 59, 59, 999)
          }
        }),
        ...(reportType === 'bill' && {
          statusToExport: Object.keys(statusToExport),
          billColumnsToExport: Object.keys(billColumnsToExport)
        })
      }
    }

    try {
      setIsExporting(true);
      const url = '/api/generateReport';
      const fileName = `FFT_Report_${moment().format('DD-MM-YYYY-HH-MM-SS')}.xlsx`;
      const fileResponse = await axios.post(url, formData, { responseType: 'blob' });


      FileSaver.saveAs(new Blob([fileResponse.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), fileName);
      dispatch(showSnack('EXPORT_EXCEL_SUCCESS', {
        label: `Successfully Exported the requested data..!`,
        timeout: 3000
      }));
    } catch (error) {
      console.log('error', error);
      dispatch(showSnack('EXPORT_EXCEL_ERROR', {
        label: 'Something went wrong..!',
        timeout: 3000
      }));
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Reports"
      />
      <Divider />
      <CardContent className={classes.cardBody}>
        <Table>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '80%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Report Type</TableCell>
              <TableCell>
                <RadioGroup row value={formState.values.reportType} onChange={handleChange}>
                  {
                    ['bill'].map(type => (
                      <FormControlLabel
                        name="reportTypes"
                        value={type}
                        control={
                          <Radio color="primary" />
                        }
                        label={`${type[0].toUpperCase()}${type.slice(1)}`}
                      />
                    ))
                  }
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Date Range</TableCell>
              <TableCell>
                <RadioGroup row value={formState.values.dateRangeType} onChange={handleChange}>
                  {
                    Object.keys(dateRanges).map(type => (
                      <FormControlLabel
                        name="dateRangeType"
                        value={type}
                        control={
                          <Radio color="primary" />
                        }
                        label={dateRanges[type]}
                      />
                    ))
                  }
                </RadioGroup>
              </TableCell>
            </TableRow>
            {formState.values.dateRangeType === 'specificDates' &&
              <>
                <TableRow>
                  <TableCell variant="head">From Date</TableCell>
                  <TableCell>
                    <TextField
                      error={hasError('fromDate')}
                      margin="dense"
                      format="DD-MM-YYYY"
                      name="fromDate"
                      onChange={handleChange}
                      required
                      defaultValue={new Date().toISOString().substring(0, 10)}
                      value={formState.values.fromDate || ''}
                      type="date"
                      variant="standard"
                      InputProps={{ inputProps: { max: new Date().toISOString().substring(0, 10) } }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">To Date</TableCell>
                  <TableCell>
                    <TextField
                      error={hasError('toDate')}
                      margin="dense"
                      format="DD-MM-YYYY"
                      name="toDate"
                      onChange={handleChange}
                      required
                      defaultValue={new Date().toISOString().substring(0, 10)}
                      value={formState.values.toDate || ''}
                      type="date"
                      variant="standard"
                      InputProps={{ inputProps: { max: new Date().toISOString().substring(0, 10) } }}
                    />
                  </TableCell>
                </TableRow>
              </>
            }
            {
              formState.values.reportType === 'bill' &&
              <>
                <TableRow>
                  <TableCell variant="head">Bill Columns</TableCell>
                  <TableCell>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {
                          Object.keys(billColumns).map(column => (
                            <FormControlLabel
                              disabled={column === 'billId'}
                              control={<Checkbox checked={formState.values.billColumnsToExport[column]} onChange={e => handleChange(e, 'billColumns')} name={column} />}
                              label={billColumns[column]}
                            />
                          ))
                        }
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Device Types</TableCell>
                  <TableCell>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {
                          Object.keys(deviceTypes).map(device => (
                            <FormControlLabel
                              control={<Checkbox checked={formState.values.deviceTypesToExport[device]} onChange={e => handleChange(e, 'deviceTypes')} name={device} />}
                              label={deviceTypes[device]}
                            />
                          ))
                        }
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Status</TableCell>
                  <TableCell>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {
                          Object.keys(statusMappings).map(status => (
                            <FormControlLabel
                              control={<Checkbox checked={formState.values.statusToExport[status]} onChange={e => handleChange(e, 'billStatus')} name={status} />}
                              label={statusMappings[status]}
                            />
                          ))
                        }
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
              </>
            }
            <TableRow>
              <TableCell>
                <Button
                  color="primary"
                  onClick={onExportClick}
                  disabled={!formState.isValid || isExporting}
                  variant="contained"
                >
                  {!isExporting ? <>Export</> : <>Exporting ...</>}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

ReportsType.propTypes = {
  className: PropTypes.string
};

export default ReportsType;
