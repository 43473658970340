import axios from 'axios';
import { FETCH_APP_CUSTOMERS, FETCH_APP_CUSTOMERS_SUCCESS, FETCH_APP_CUSTOMERS_FAILURE } from '../constants';
import { dispatch } from '../../../helpers/store-helper';

export const fetchAppCustomersStart = () => ({ type: FETCH_APP_CUSTOMERS });

export const fetchAppCustomersSucess = data => ({
  payload: {
    data
  },
  type: FETCH_APP_CUSTOMERS_SUCCESS
});

export const fetchAppCustomersFailure = () => ({ type: FETCH_APP_CUSTOMERS_FAILURE });

export const getAppCustomers = () => async () => {
  const url = '/api/customers';
  try {
    dispatch(fetchAppCustomersStart());
    const request = await axios.get(url);
    const { data } = request;
    dispatch(fetchAppCustomersSucess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAppCustomersFailure());
  }
}
